import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Button,  } from 'react-bootstrap';
import { NavLink,useParams } from "react-router-dom";
import './UserStrdtl.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';

const UserStrdtl = () => {
const { id } = useParams()
const [inputs,setInputs] = useState();
const [loading,setLoading] = useState(true);
const [coupon, setCoupon] = useState([]);

useEffect(() => {
      document.title = `User Store Details | Blue Faucet`;
      document.getElementById('bodywrap').className='usdtlpgmain';
      window.scrollTo({top: 0,left: 0,behavior: 'smooth', }); 
	
	  /* Start get store data */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('store_id', id);
		API.post('store_detail', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			   setInputs(response.data.data);
			   setCoupon(response.data.data.coupon);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get store data */ 
},[id])  


/* Start view reating */
/*const ratingview = (count) => {
	var html='';
	for (let i = 1; i <= count; i++) {
			html+='<label aria-label="1 star" class="rating__label" ><i class="rating__icon rating__icon--star fa fa-star"></i></label><input class="rating__input" name="rating"  defaultValue={i} type="radio"/>';
	
	}
	var cou=count+1;
	for(let a=cou;a <= 5;a++)
	{
		html+='<label aria-label="1 star" class="rating__label" ><i class="rating__icon rating__icon--star fa fa-star rating-emt"></i></label><input class="rating__input" name="rating"   type="radio"/>';
	}
	return <div dangerouslySetInnerHTML={{__html: html}} />
}*/
/* End view reating */
 
 const addressview = (street_name,state_name,city_name,zip_code,lat,long,mobile) => {
	var html='';
    var i=0;
	if(street_name!=='')
	{
		html+=street_name+', ';
		i++;
	}
	/*if(state_name!=='')
	{
		html+=state_name+', ';
		i++;
	}
	if(city_name!=='')
	{
		html+=city_name+', ';
		i++;
	}*/
	if(zip_code!=='')
	{
		html+=' - '+zip_code;
		i++;
	}
	
	var html1='<div class="row mx-0">';
	html1+='<div class="col-md-10 col-8 mb-2 usdtl-textlft" >';
	if(i >0 || (lat!=='' && long!=='')){
    html1+='<p>Address</p>';
	}
    html1+='</div>';
    html1+='<div class="col-md-2 col-4 mb-2 text-end usdtl-icn">';
	if(lat!=='' && long!=='')
	{
		html1+='<a target="_blank" href="https://www.google.com/maps/search/?api=1&query='+lat+','+long+'"><img src="'+process.env.REACT_APP_BASE_URL + '/assets/images/svg/location.svg" className="img-fluid" alt="Sld Img"/></a>';
	}
	html1+='</div>';
	html1+='<div class="col-md-12 col-12 mb-3 usdtl-textlft cntdtl" >';
    html1+='<p>'+html+'</p>';
    html1+='</div>';
	html1+='<div class="col-md-10 col-8 mb-2 usdtl-textlft cntdtl">';
	html1+='<p>'+mobile+'</p>';
	html1+='</div>';
	
	 html1+='<div class="col-md-2 col-4 mb-2 text-end usdtl-icn">';
	 html1+='<a href="tel:'+inputs.mobile+'">'
	 html1+='<img src="'+process.env.REACT_APP_BASE_URL + '/assets/images/svg/call.svg" className="img-fluid" alt="Sld Img"/>';
	 html1+='</a>';
	 html1+='</div>';
	html1+='</div>';
	
	return <div dangerouslySetInnerHTML={{__html: html1}} />
}
/* Start like and dislike */
const like = (id) => {		
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('store_id', id);
	API.post('store_favourite', formData)
		.then(response => {
		
		if(response.data.status === 0){
			
		}else{
			if(response.data.message==='Store is Favourite.')
			{
				
				var store_fav_lbl='store_favourite_status';
				setInputs(inputs => ({...inputs, [store_fav_lbl]: 1}));
			}else{
				var store_fav_lbl1='store_favourite_status';
				setInputs(inputs => ({...inputs, [store_fav_lbl1]: 0}));
			}
		}                  
	}).catch(error => {
		 
	});  
}
/* End like and dislike */
 const getCoupontype = (val,discount_percentage,minimum_purchase_amount,item_name_buy,product_item_name,buy_qty,get_qty) => {
	var type='';
	if(val==='Buy & Get')
	{
		type='Buy  '+buy_qty+' '+item_name_buy+' and Get '+get_qty+' '+product_item_name+' free of Charge';
	}else if(val==='Discount Offer'){
		type=discount_percentage+'% discount when purchase over $'+minimum_purchase_amount;
	}else{
		type=val;
	}
	return type;
}
 
    return (
        <>
        {/* ========== User Store Detail Page Start ========== */}
         {inputs && (
		 <section className="gen-wrap">
         {/* ========== Navbar Start ========== */}
            <Navbar headerText={inputs.store_name}/>
         {/* ========== Navbar End ========== */}
            
			<Container>
               <Row>
                  <Col md={12} xs={12} className="px-0 usdtlsld">
                  {/* ========== OwlCarousel Top Slider Start ========== */}
                  <OwlCarousel items={1}  className='owl-theme' loop margin={10} >
                     {inputs.image && inputs.image.map((val,index) => {
						return(
					 <Col className="">
                     <img src={val.image} className="img-fluid img-store" alt="Sld Img"/>
                     </Col> 
					  )
					  })}
                       
                  </OwlCarousel>
                  {/* ========== OwlCarousel Top Slider End ========== */}
                  </Col>
               </Row>
               <Row className="mx-0 mianhmdwncnt">
               {/* ========== Store Detail Content Start ========== */}
                  <Col className="mnhmcnt my-3">
                  <Row className="sldrdwncnt">
                     <Col md={6} xs={7}>
					 <img src={inputs.logo} className="img-fluid img-store-logo" alt="Sld Img"/>
                     <div className="store-des-text">
						 <p>{inputs.category_name}</p>
						 <h3>{inputs.store_name}</h3>
						 <p>{inputs.store_email}</p>
					 </div>
					 
                     </Col>
                     <Col md={6} xs={5} className="text-end fav-icon-pointer">
						 {/*<div id="str-trng" className="mb-1">
                        <div className="rating-group strrtg-rating">
                           {ratingview(inputs.star)}
                        </div>
                        <span>({inputs.total_rating} ratings)</span>
						 </div>*/}
						 {inputs.store_favourite_status ? <svg className={`lik lik${inputs.id}`} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" onClick={() =>
                           like(inputs.id)}>
								   <circle cx="12" cy="12" r="12" fill="white"/>
								   <path d="M8.79002 6.9229C8.31907 7.0049 7.74971 7.25093 7.38185 7.52975C6.7586 8.0007 6.29468 8.73173 6.10255 9.5354C5.99945 9.97589 5.96899 10.6437 6.0346 11.0701C6.20564 12.1854 6.87107 13.2445 8.16677 14.4628C8.64475 14.915 8.97981 15.2103 10.3458 16.3841C11.187 17.1105 11.6064 17.4549 11.6954 17.4994C11.8782 17.5861 12.1218 17.5861 12.3046 17.4994C12.485 17.4127 14.9944 15.2524 15.8332 14.4628C17.1289 13.2445 17.7944 12.1854 17.9654 11.0701C18.031 10.6437 18.0005 9.97589 17.8975 9.5354C17.7053 8.73173 17.2414 8.0007 16.6181 7.52975C16.2409 7.2439 15.6762 7.00256 15.1865 6.9229C14.9218 6.87838 14.3805 6.87838 14.1181 6.9229C13.3918 7.04942 12.7099 7.45243 12.1968 8.06396C12.0961 8.18346 12.0094 8.28187 12 8.28187C11.9906 8.28187 11.9039 8.18346 11.8032 8.06396C11.2901 7.45243 10.6082 7.04942 9.88188 6.9229C9.62883 6.88072 9.04307 6.88072 8.79002 6.9229Z" fill="#FF0000"/>
								</svg>  : <svg className={`dis dis${inputs.id}`} width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>
								   like(inputs.id)}>
								   <circle id="Ellipse_40" data-name="Ellipse 40" cx="12" cy="12" r="12" fill="#fff"/>
								   <g id="Black">
									  <path id="Vector" d="M8.79,6.923a3.579,3.579,0,0,0-1.408.607A3.579,3.579,0,0,0,6.1,9.535a4.716,4.716,0,0,0-.068,1.535,6.039,6.039,0,0,0,2.132,3.393c.478.452.813.748,2.179,1.921.841.726,1.261,1.071,1.35,1.115a.746.746,0,0,0,.609,0c.18-.087,2.69-2.247,3.529-3.037a6.039,6.039,0,0,0,2.132-3.393A4.719,4.719,0,0,0,17.9,9.535,3.579,3.579,0,0,0,16.618,7.53a3.491,3.491,0,0,0-1.432-.607,4.29,4.29,0,0,0-1.068,0A3.262,3.262,0,0,0,12.2,8.064a1.991,1.991,0,0,1-.2.218,1.991,1.991,0,0,1-.2-.218A3.262,3.262,0,0,0,9.882,6.923,4.726,4.726,0,0,0,8.79,6.923Z" fill="none" stroke="black" strokeWidth="1"/>
								   </g>
								</svg>
						 } 
                     <p>{inputs.distance} - Address Here </p>
                     </Col>
                     <Col md={12} xs={12} className="adrsdv">
                     
					 {addressview(inputs.street_name,inputs.state_name,inputs.city_name,inputs.zip_code,inputs.lat,inputs.long,inputs.mobile)}
						 
                     </Col>
                     <Col md={12} xs={12} className="px-0">
                     <Row className="btn-usrstrdtl mt-3 mx-0">
                        <Col>
                         <NavLink to={`/product/${inputs.id}`}><Button type="button" className="lft-btn">Products </Button></NavLink></Col>
                        <Col>
                         <NavLink to={`/service/${inputs.id}`}><Button type="button" className="rgt-btn">Services </Button></NavLink></Col>
                     </Row>
                     </Col>
                     <Col className="usdtllstdv">
                     <div className="tptxtcnt mb-1 mt-2">
                        <p>Coupon Box</p>
                         <NavLink to="/coupon-list">View All</NavLink>
                     </div>
                     {/* ========== OwlCarousel Coupon Box Start ========== */}
                     <Col md={12} xs={12} className="mb-3 usdtllstsldr">
					  {coupon.length >0 &&(
                     <OwlCarousel items={1.2}  className='owl-theme owl-img-coup' loop={coupon.length >1} margin={20} >
							{coupon && coupon.map((val,index) => {
							return (
						<div>
						  {(() => {
							if (val.image==='1') {
							  return (<Col md={12} xs={12} className="cupn-crd coup-height">
							   <div className="coupon">
								  <div className="left">
									 <div>Discount</div>
								  </div>
								  <div className="center">
									 <div>
										<h2>{val.coupon_name}</h2>
										<p>{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
										<p>{val.description}</p>
											{val.return_befor ==='' ? (
												<p>Start Date : <span>{val.start_date}</span> &nbsp;&nbsp;End Date :<span>{val.end_date}</span> </p>
											) : (
												<p>Return Befor : <span>{val.return_befor}</span> </p>
											)}
									 </div>
								  </div>
							   </div>
							   </Col> )
							} else if (val.image==='2') {
							  return (<Col className="mb-3 main-copn coup-height">
								  <Row className="mx-0 second-coupan mb-2">
								  <Col className="align-self-center copn-text">
								    <h5>{val.coupon_name}</h5>
										<p>{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
										<p>{val.description}</p>
										{val.return_befor ==='' ? (
										  <>
											<p>Start Date : <span>{val.start_date}</span> </p>
											<p>End Date :<span>{val.start_date}</span> </p>
										  </>
										) : (
											<p>Return Befor : <span>{val.return_befor}</span> </p>
										)}
								  </Col>
								  <Col className="align-self-end text-end"><img src={process.env.REACT_APP_BASE_URL + '/assets/images/cupn-img.png'} className="img-fluid w-100" alt="Hmtp Postr"/></Col>
								 </Row>
							 </Col>)
							}else if (val.image==='3') {
							  return (<Col className="mb-3 main-copn coup-height">
								<Row className="mx-0 third-copn ">
								   <Col className="align-self-end text-white col copn-text text-end mb-5 px-5">
									<h5 className="text-dark">{val.coupon_name}</h5>
										<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
										<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
												<p className="text-dark">Start Date : <span>{val.start_date}</span> &nbsp;&nbsp;End Date :<span>{val.end_date}</span> </p>
											) : (
												<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
								   </Col>
								  </Row>
							</Col>)
							}else if (val.image==='4') {
							  return (<Col className="mb-3 main-copn coup-height">
									{(() => {
								if (val.coupon_type==='Welcome coupon'){
									return (<Row className="mx-0 four-copn ">
									   <Col className="align-self-end text-dark col copn-text text-end">
										<h5 className="text-dark">Welcome to {val.store_name}</h5>
											<p className="text-dark">We appreciate your membership enrollment. Enjoy a {val.discount_percentage}% discount on your upcoming visit as a token of our gratitude. We look forward to providing you with excellent service during your visit.</p>
									   </Col>
									  </Row>)
									}else{
										return (<Row className="mx-0 four-copn ">
									   <Col className="align-self-end text-dark col copn-text text-end">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
												<p className="text-dark">Start Date : <span>{val.start_date}</span> &nbsp;&nbsp;End Date :<span>{val.end_date}</span> </p>
											) : (
												<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>)
									}										
								})()}  
								</Col>)
							}else if (val.image==='5') {
							  return (<Col className="mb-3 main-copn coup-height">
									<Row className="mx-0 five-copn justify-content-center ">
									   <Col md={6} xs={8} className="align-self-center text-dark col copn-text col-6">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (val.image==='6') {
							  return (<Col className="mb-3 main-copn coup-height">
									<Row className="mx-0 six-copn justify-content-center ">
									   <Col md={6} xs={8} className="align-self-center text-dark col copn-text col-6">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (val.image==='7') {
							  return (<Col className="mb-3 main-copn coup-height">
									<Row className="mx-0 seven-copn justify-content-end ">
									   <Col md={6} xs={8} className="align-self-center text-white col copn-text text-end col-6 mt-5">
										<h5 className="">{val.coupon_name}</h5>
											<p>{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
										<p>{val.description}</p>
										{val.return_befor ==='' ? (
										  <>
											<p>Start Date : <span>{val.start_date}</span> </p>
											<p>End Date :<span>{val.start_date}</span> </p>
											</>
										) : (
												<p>Return Befor : <span>{val.return_befor}</span> </p>
										)}
									   </Col>
									  </Row>
								</Col>)
							}else if (val.image==='8') {
							  return (<Col className="mb-3 main-copn coup-height">
									<Row className="mx-0 eight-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (val.image==='9') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 nine-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">Welcome to {val.store_name}</p>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (val.image==='10') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 ten-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">Welcome to {val.store_name}</p>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (val.image==='11') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 eleven-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{val.coupon_name}</h5>
											<p className="text-dark">Welcome to {val.store_name}</p>
											<p className="text-dark">{getCoupontype(val.coupon_type,val.discount_percentage,val.minimum_purchase_amount,val.item_name_buy,val.product_item_name,val.buy_quantity,val.get_quantity)}</p>
											<p className="text-dark">{val.description}</p>
											{val.return_befor ==='' ? (
											  <>
												<p className="text-dark">Start Date : <span>{val.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{val.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Befor : <span>{val.return_befor}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}        
						  })()}
						</div>
					  )
					  })}  
                     </OwlCarousel>
					 )}
                     </Col>
                     </Col>
                  </Row>
                  </Col>
                  {/* ========== OwlCarousel Coupon Box End ========== */}
               </Row>
			   {loading && (
				   <div className="loadingClass">
						<img src={loader} alt="loader"/>
					</div>
				   )} 
            </Container>
			
         </section>
		 )}
         {/* ========== User Store Detail Page End ========== */}
        </>
    )
}

export default UserStrdtl;