import React, { useEffect, useState} from 'react';
import { Row, Col, Container, Button, Table, Stack, Form, Modal } from 'react-bootstrap';
import { NavLink,useParams } from "react-router-dom";
import './UserRcipt.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess,gettoastwarning } from '../../../Utils/Common';


const UserRefundRciptService = () => {
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);


const [loading,setLoading] = useState(true);
const { id } = useParams()
const [inputs,setInputs] = useState({order_id:'',order_number:'',tips:'',saving_amount:0,sales_tax_rate_per:0,sales_tax_rate:0,amount:'',subtotal:'',total:'',total_due:'',date:'',due_date:'',store_name:'',store_image:'',store_address:'',store_area:'',store_city:'',store_state:'',store_mobile:'',store_zip:'',user_address_title:'',user_address_street_name:'',user_address_state:'',user_address_city:'',user_address_zip_code:'',user_mobile:'',user_name:'',payment_txn_id:'',payment_type:'',payment_card_name:'',payment_card_number:'',payment_card_month:'',payment_card_year:'',status:4,status_name:'Paid',refund_price:'',refund_discount:'',refund_tax:'',refund_total:'',refund_date:'',employee_name:'',total_return:0,return_coupon_name:'',agree:0});
const [orderrev, setOrderrev] = useState([]);
const [coupon,setCoupon] = useState([]);

const [showhelp, setShowhelp] = useState(false);
const handleCloseHelp = () => { setShowhelp(false); }

useEffect(() => {
      document.title = `User Receipt | Blue Faucet`;
      document.getElementById('bodywrap').className='usrrcptcpgmain';

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	
	/* Start get order reciept */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('id', id);
		API.post('get_order_by_id', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				
				var sales_tax_rate_per=response.data.data.sales_tax_rate_per;
			var sales_tax_rate=response.data.data.sales_tax_rate;
				
				var saving_amount=0;
				if(response.data.data.saving_amount!=='')
				{
					saving_amount=parseFloat(response.data.data.saving_amount);
				}
				var subtotal=parseFloat(response.data.data.subtotal);
				var total=subtotal-saving_amount;
				var tips=parseFloat(response.data.data.tips);
				var total_due=total+tips+sales_tax_rate;
				
				tips=tips.toFixed(2);
				saving_amount=saving_amount.toFixed(2);
				sales_tax_rate=sales_tax_rate.toFixed(2);
				subtotal=subtotal.toFixed(2);
				total=total.toFixed(2);
				total_due=total_due.toFixed(2);
				
				var refund_price=response.data.data.refund_price;
				var refund_discount=response.data.data.refund_discount;
				var refund_tax=response.data.data.refund_tax;
				var refund_total=response.data.data.refund_total;
				refund_price=refund_price.toFixed(2);
				refund_discount=refund_discount.toFixed(2);
				refund_tax=refund_tax.toFixed(2);
				refund_total=refund_total.toFixed(2);
				
				setInputs({
					order_id:response.data.data.order_id,
					order_number:response.data.data.order_number,
					tips:tips,
					saving_amount:saving_amount,
					sales_tax_rate_per:sales_tax_rate_per,
				    sales_tax_rate:sales_tax_rate,
					amount:response.data.data.amount,
					subtotal:subtotal,
					total:total,
					total_due:total_due,
					date:response.data.data.date,
					due_date:response.data.data.due_date,
					store_name:response.data.data.store_name,
					store_image:response.data.data.store_image,
					store_address:response.data.data.store_address,
					store_area:response.data.data.store_area,
					store_city:response.data.data.store_city,
					store_state:response.data.data.store_state,
					store_mobile:response.data.data.store_mobile,
					store_zip:response.data.data.store_zip,
					user_address_title:response.data.data.user_address_title,
					user_address_street_name:response.data.data.user_address_street_name,
					user_address_state:response.data.data.user_address_state,
					user_address_city:response.data.data.user_address_city,
					user_address_zip_code:response.data.data.user_address_zip_code,
					user_mobile:response.data.data.user_mobile,
					user_name:response.data.data.user_name,
					payment_txn_id:response.data.data.payment_txn_id,
					payment_type:response.data.data.payment_type,
					payment_card_name:response.data.data.payment_card_name,
					payment_card_number:response.data.data.payment_card_number,
					payment_card_month:response.data.data.payment_card_month,
					payment_card_year:response.data.data.payment_card_year,
					status:response.data.data.status,
					status_name:response.data.data.status_name,
					refund_price:refund_price,
					refund_discount:refund_discount,
					refund_tax:refund_tax,
					refund_total:refund_total,
					refund_date:response.data.data.refund_date,
					employee_name:response.data.data.employee_name,
					total_return:response.data.data.total_return,
					return_coupon_name:response.data.data.return_coupon_name,
					agree:response.data.data.agree,
				});
				
				var serv=response.data.data.order_array;
				//rec(serv)
				const  tempArr = serv.map(item => ({...item, isSelected: false}))
				
			   setOrderrev(tempArr);
				setCoupon(response.data.data.coupon);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get order reciept */
	
},[id])  

/* Start select service */
const handleSelectChange = (id) => {
	let updateData = orderrev.map((item, i) => {
		  return id === item.id ? { ...item, isSelected: !item.isSelected } : item;
		});
		setOrderrev(updateData);
}
/* End select service */ 
const RefundSubmit = () => {
	 setLoading(true);
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('id', id);
	formData.append('order_detail', JSON.stringify(orderrev));
	API.post('refund_order_by_customer', formData)
		.then(response => {
		setLoading(false);
		if(response.data.status === 0){
			//alert(response.data.message);
			gettoastwarning(response.data.message,'');
		}else{
			//alert(response.data.message);
			 //window.location.href = "/order-payment";
			 setShow(false);
			 gettoastsuccess(response.data.message,'/order-payment');
		}                  
	}).catch(error => {
		 
	});  
}

const addressview = (user_address_street_name,user_address_state,user_address_city,user_address_zip_code) => {
	var html='';
	if(user_address_street_name!=='')
	{
		html+=user_address_street_name+', ';
	}
	/*if(user_address_state!=='')
	{
		html+=user_address_state+', ';
	}
	if(user_address_city!=='')
	{
		html+=user_address_city+', ';
	}*/
	if(user_address_zip_code!=='')
	{
		html+=user_address_zip_code;
	}
	return <div dangerouslySetInnerHTML={{__html: html}} />
}

const handleShowHelp = () => {
	setShowhelp(true);
}

    return (
        <>
        {/* ========== User Receipts Page Start ========== */}
         <section className="gen-wrap">
		 <ToastContainer />
         {/* ========== Navbar Start ========== */}
               <Navbar headerText={'Refund Receipt'}/>
         {/* ========== Navbar End ========== */}
         <Container>
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
               {/* ========== Receipts Content Start ========== */}
               <Col md={12} xs={12} className="py-2">
               <Row className="mx-0 maincnt-recepts">
                  <Col sm={10} xs={12} className="rrsone">
                  <h3>{inputs.store_name}</h3>
                  <p>{inputs.store_address} , {inputs.store_zip}</p>
                  <p>Phone: {inputs.store_mobile}</p>
				  {/*<h2 className="mt-4 cus-add-lab">Customer Address</h2>
				  <h3>{inputs.user_name}</h3>
				  <h3>{inputs.user_address_title} </h3>
                  <p>{addressview(inputs.user_address_street_name,inputs.user_address_state,inputs.user_address_city,inputs.user_address_zip_code)}</p>
                  <p>Phone: {inputs.user_mobile}</p>*/}
				  <div className="left-cnt-rcptr">
				  <h4>Customer Name: <span>{inputs.user_name}</span></h4>
				  {inputs.agree===1 ?
				  <h4>Contact No: <span>{inputs.user_mobile}</span></h4>
				  : null }
				  {inputs.user_address_street_name!=='' ?
					<h4>Customer address: <span>{addressview(inputs.user_address_street_name,inputs.user_address_state,inputs.user_address_city,inputs.user_address_zip_code)}</span></h4>
				 : null }
				 
				  </div>
                  <div className="dwncmt">
				  {/*<h4>Serviced by: <span>xxxxxxxxxxxx</span></h4>*/}
                     <h5>Information:</h5>
					 <h4>Schedule date & time:<span>{inputs.due_date}</span></h4>
                     <h4>Invoice No.<span>{inputs.order_number}</span></h4>
                     <h4>Invoice Date: <span>{inputs.date}</span></h4>
					 {inputs.employee_name!=='' ?
					<h4>Employee Name: <span>{inputs.employee_name}</span></h4>
						: null }
                  </div>
                  </Col>
                  <Col sm={2} xs={12} className="text-end left-cnt-rcpt rrstwo">
                  <img src={inputs.store_image} className="img-fluid" alt="Hm Img"/>
					  {/*<h4>Due Date:<span>20May 2023</span></h4>*/}
                  </Col>
				  
                  <Col md={12} xs={12} className="tblinvcpg rrsthr">
                  {/* ========== Table Content Start ========== */}
                  <Table responsive>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Service/Items</th>
                           <th>Price</th>
						    <th>Q’ty</th>
                           <th>Amount</th>
                        </tr>
                     </thead>
                     <tbody>
					  {orderrev && orderrev.map((val,index) => {
						return(
							<tr className={val.status ? "textdecoration-line-through" : ""}>
							   <td>{index+1}</td>
							   <td>{val.product_name}</td>
							   
							   <td>${val.product_price}</td>
							   <td>1</td>
							   <td>${val.total}</td>
							</tr>
                        )
					  })}  
                     </tbody>
                  </Table>
                  {/* ========== Table Content End ========== */}
				  <Row>
				  <Col sm={7} xs={12} className="py-2 rrsleft">
					<div className="text-left tbldwndv">
						<h3>Refund information <NavLink to="#" onClick={() =>handleShowHelp()}><img src={process.env.REACT_APP_BASE_URL + '/assets/images/green.png'} className="img-fluid img-help" alt="Logo"/></NavLink></h3>
						<p><b>The Price after refund and discount: <span className="text-danger">${inputs.refund_price}</span></b></p>
							{/*<p>Coupon or Discount: <span className="text-danger">-${inputs.refund_discount}</span></p>*/}
						<p><b>The tax for the price: 	 <span className="text-danger">${inputs.refund_tax}</span></b></p>
						<p><b>Refund Total Amount: <span className="text-danger">${inputs.refund_total}</span></b></p>
						<p><b>Refund Date: <span className="text-danger">{inputs.refund_date}</span></b></p>
						
						<p><b>Your refund has been processed and returned to your original payment method</b></p>
						
					</div>
				  </Col>
				  <Col sm={5} xs={12} className="py-2 rrsright">
                  <div className="text-end tbldwndv">
                     <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Subtotal</p>
                        </div>
                        <div className="pb-1"><span>${inputs.subtotal}</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Coupon or discount</p>
                        </div>
                        <div className="pb-1">-<span>${inputs.saving_amount}</span></div>
                     </Stack>
					 <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Tax ({inputs.sales_tax_rate_per}%)</p>
                        </div>
                        <div className="pb-1"><span>${inputs.sales_tax_rate}</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Gratuity</p>
                        </div>
                        <div className="pb-1 ps-3"><span> {inputs.tips} </span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4} className="lstprcdv">
                        <div className=" ms-auto">
                           <h6>Total Due</h6>
                        </div>
                        <div className="">
                           <h6>${inputs.total_due}</h6>
                        </div>
                     </Stack>
                  </div>
				  </Col>
				  </Row>
				  {coupon.length >0 &&(
						<div className="mb-3 mt-3">
						<label className="form-label mb-4">Coupon</label>
					  <Row>
					  
						<Col md={12} xs={12}>
							<ul className="sldr-add">
								{coupon.length > 0 && coupon.map((item,index) => {
							return(<li className="sldr-in-add">
								<div className="row mincrd">
								<Col md={12} xs={12} className="align-self-center mb-2">
									<h4>{item.coupon_type}</h4>
								   </Col>
									
									<Col md={12}>
										{(() => {
										if (item.coupon_type==='Buy & Get') {
											return (<p>Buy {item.buy_quantity} get {item.get_quantity} free,for {item.product_item_name}</p>)
										}else if(item.coupon_type==='Discount Offer'){
											return (  <p>{item.discount_percentage} % Discount When Purchase Over ${item.minimum_purchase_amount}</p>)
										}else if(item.coupon_type==='Loyalty Setup'){
											return (<p>Buy {item.buy_quantity_needed} {item.product_applicable_item_if_any} get {item.product_get_item_name} free</p>)
										}else if(item.coupon_type==='Invitation'){
											return (<p>{item.product_item_name}</p>)
										}else if(item.coupon_type==='Welcome coupon'){
											return (<p>{item.discount_percentage}% for all new members joining your membership has been set up</p>)
										}								
										})()}
									   {(() => {
										if (item.coupon_type!=='Welcome coupon') {
											return (<p>{item.description}</p>)
										}									
										})()}
									</Col>
									</div>
								</li>
								)})}
							</ul>
						</Col>
						
						</Row>
						</div>
					  )}
                  </Col>
                  <Col md={6} xs={7} className="dwn-cntrecpt">
				 {(() => {
					if (inputs.status===6){
						return (<><h3>Transaction Records</h3><p>Payment Type: Cash</p></>)
				 }
				 })()}
				 </Col>
               </Row>
               <Col className="usrrcptbtn mt-3">
			   
					<NavLink to="#"><Button variant="" type="submit">{inputs.status_name}</Button></NavLink>
		
                <div className="inmodal">
                 {/* ========== Tabel Popup Start ========== */}
                    <Modal show={show} onHide={handleClose} className="popupwrngcnt">
                       <Modal.Header closeButton>
                          <h3 className="mdlttltxt">Select Items For Refund</h3>
                       </Modal.Header>
                       <Modal.Body>
                          <Row>
                             <Col md={12} xs={12} className="tblinvcpg">
                               <Table responsive>
                                 <thead>
                                    <tr>
                                       <th>#</th>
                                       <th>Service/Items</th>
                                       <th>Price</th>
                                       <th>Q’ty</th>
                                       <th>Amount</th>
                                    </tr>
                                 </thead>
                                 <tbody>
								 {orderrev && orderrev.map((val,index) => {
						          return(
                                    <tr>
                                       <td><Form.Check aria-label="option 1" checked={val.isSelected} onClick={() =>handleSelectChange(val.id)} /></td>
                                       <td>{val.product_name}</td>
                                       <td>${val.total}</td>
                                       <td>1</td>
                                       <td>${val.total}</td>
                                    </tr>
                                    )
					             })}
                                 </tbody>
                              </Table>
                             </Col>
							 
                             <Col md={12} xs={12} className="btn-dv mt-3">
                             <NavLink to="#" onClick={RefundSubmit}><Button variant="">Submit For Refund</Button></NavLink>
                             </Col>
                          </Row>
                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>
                  {/* ========== Tabel Popup End ========== */}
                 </div>
               </Col>
               </Col>
               {/* ========== Receipts Content End ========== */}
               </Col>
            </Row>
			{loading && (
		   <div className="loadingClass">
				<img src={loader} alt="loader"/>
			</div>
		   )} 
         </Container>
      </section>
      {/* ========== User Receipts Page End ========== */}
	  {/* ========== Help Popup Start ========== */}
		<Modal show={showhelp} onHide={handleCloseHelp} className="popupwrngcnt" centered>
			   <Modal.Header closeButton>
				  <h3 className="mdlttltxt">Refund Calculation How Works</h3>
			   </Modal.Header>
			   <Modal.Body>
				  <Row>
					 <Col md={12} xs={12} className="tblinvcpg help-text-justify ">
		  <p>Your refund request involves coupons that were applied for a discount, which may be adjusted due to coupon requirements. Here is the equation to recalculate your refund based on the new order:
Product Price Difference after Discount + Tax Difference = Total Refund Amount</p>
		</Col>
					 
					 
				  </Row>
			   </Modal.Body>
			   <Modal.Footer className="d-none"></Modal.Footer>
			</Modal>
	  {/* ========== Help Popup End ========== */}
      </>
    )
}

export default UserRefundRciptService;