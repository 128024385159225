import React, { useEffect, useState } from 'react';
import {  Row, Col, Container, Button,Table,Tab,Nav,Modal,Stack } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './UserOrdrsmry.css';
import './slder.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import OwlCarousel from 'react-owl-carousel'; 
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {validateaddressprofile} from '../../../Utils/validate';
import PlacesAutocomplete, { geocodeByAddress,getLatLng  } from 'react-places-autocomplete'; 
//import { useNavigate } from 'react-router-dom';
// import $ from 'jquery';
import { Stepper } from "@progress/kendo-react-layout";
import { Form, FormElement } from "@progress/kendo-react-form";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import {validateorderser} from '../../../Utils/validate';
import moment from "moment";
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess,gettoastwarning } from '../../../Utils/Common';


const UserOrdrsmrynew = () => {

	const [loading,setLoading] = useState(true);
	
	const [coupon,setCoupon] = useState([]);
	const [couponid,setCouponid] = useState([]);
	
	const [show, setShow] = useState(false);
	const handleClose = () => { setShow(false); }
	
	const [show3, setShow3] = useState(false);
	const handleClose3 = () => setShow3(false);
	
	const [error,setError] = useState();
	const [errors,setErrors] = useState({});
	
	const [inputsc,setInputsc] = useState({store_name:'',store_address:'',store_zip:'',store_mobile:'',user_name:'',user_mobile:'',order_number:'',date:'',store_image:''});
	
	const [inputs,setInputs] = useState({user_name:'',mobile:'',garnd_total:0,payable_total:0,savings_total:0,sales_tax_rate_per:0,sales_tax_rate:0,tips:0,subtotal:0,saving_amount:0,total_due:0,credit_card_name:'',credit_card_number:'',credit_card_month:'',credit_card_year:'',credit_card_cvv:'',debit_card_name:'',debit_card_number:'',debit_card_month:'',debit_card_year:'',debit_card_cvv:'',address:0,add_status:true,agree:1});
	const [orderdata,setOrderdata] = useState([]);
	
	
	
	const [showadd,setShowadd] = useState(false);
	const [address,setAddress] = useState({title:'',street_name:'',state_id:'',city_id:'',latitude:'',longitude:''});
	const [address1, setAddress1] = useState("");
	
	
	const [key, setKey] = useState('first');

    const [disaddress, setDisaddress] = useState([]);


	const [orderaddress, setOrderaddress] = useState('');



  const [title, setTitle] = useState('Contact Setup');
   const [step, setStep] = React.useState(0);
    
   const [formState, setFormState] = React.useState({});
   const [steps, setSteps] = React.useState([
    {
      label: "Contact Setup",
      isValid: undefined,
    },
    {
      label: "Order Summary",
      isValid: undefined,
    },
    {
      label: "Pay The Bill",
      isValid: undefined,
    },
  ]);
     const lastStepIndex = steps.length - 1;
  const isLastStep = lastStepIndex === step;
  const isPreviousStepsValid =
    steps
      .slice(0, step)
      .findIndex((currentStep) => currentStep.isValid === false) === -1;
  const onStepSubmit = React.useCallback(
    (event) => {
      const { isValid, values } = event;
      const currentSteps = steps.map((currentStep, index) => ({

        ...currentStep,
        isValid: index === step ? isValid : currentStep.isValid,
      }));
      console.log(currentSteps);
      setSteps(currentSteps);
      setStep(() => Math.min(step + 1, lastStepIndex));
      setFormState(values);
      //setTitle(currentSteps[step+1]['label']);
      setTitle('Contact Setup');
      if (isLastStep && isPreviousStepsValid && isValid) {
        
        //alert(JSON.stringify(values));
        ///alert('ffddf');
        //window.location.href = '/product-order-confirmation';
      }
    },
    [steps, isLastStep, isPreviousStepsValid, step, lastStepIndex]
  );
  const onPrevClick = React.useCallback(
    (event) => {
      event.preventDefault();
      setStep(() => Math.max(step - 1, 0));
    },
    [step, setStep]
  );
const searchOptions = {
    componentRestrictions: { country: 'us' }
  };
useEffect(() => {
      document.title = `User Order Summary | Blue Faucet`;
      document.getElementById('bodywrap').className='uospgmain';
    
	  /* Start get order */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('type', 'service');
		formData.append('date', localStorage.getItem("book_start_date"));
		API.post('get_temp_add_to_card', formData)
			.then(response => {
			setLoading(false);
			if(response.data.status === 0){
				
			}else{
				if(response.data.data.temp_data.length < 1){
						window.location.href = '/home';
				}
				var mobile='+'+response.data.data.country_code+' '+response.data.data.mobile;
				var add_status=true;
				if(response.data.data.store_location==="Store's Location")
				{
					add_status=false;
				}
				
				var garnd_total=parseFloat(response.data.data.garnd_total);
				var tips=0;
				var sales_tax_rate_per=parseFloat(response.data.data.sales_tax_rate_per);
				
				var saving_amount=0;
				var sales_tax_rate=garnd_total*sales_tax_rate_per/100;
				var sales_tax_rate1=sales_tax_rate.toFixed(2);
				var payable_total=garnd_total+tips+parseFloat(sales_tax_rate1);
				
				
				
				var total=garnd_total-saving_amount;
				
				var total_due=parseFloat(total)+tips+parseFloat(sales_tax_rate);
				
				saving_amount=saving_amount.toFixed(2);
				
				payable_total=payable_total.toFixed(2);
				
				var subtotal=garnd_total.toFixed(2);
				
				tips=tips.toFixed(2);
				
				total_due=total_due.toFixed(2);
				
				setInputs({
					user_name:response.data.data.user_name,
					mobile:mobile,
					garnd_total:garnd_total,
                    payable_total:payable_total,
					savings_total:saving_amount,
					sales_tax_rate_per:response.data.data.sales_tax_rate_per,
					sales_tax_rate:sales_tax_rate1,
                    tips:tips,
					subtotal:subtotal,
                    saving_amount:saving_amount,
					total_due:total_due,					
					credit_card_name:response.data.data.credit_card_name,
					credit_card_number:response.data.data.credit_card_number,
					credit_card_month:response.data.data.credit_card_month,
					credit_card_year:response.data.data.credit_card_year,
					credit_card_cvv:'',
					debit_card_name:response.data.data.debit_card_name,
					debit_card_number:response.data.data.debit_card_number,
					debit_card_month:response.data.data.debit_card_month,
					debit_card_year:response.data.data.debit_card_year,
					debit_card_cvv:'',
					address:0,
					add_status:add_status,
					agree:1
				});
				setOrderdata(response.data.data.temp_data);
				
				setInputsc({
					store_name:response.data.data.store_name,
					store_address:response.data.data.store_address,
					store_zip:response.data.data.store_zip_code,
					store_mobile:response.data.data.store_mobile,
					user_name:response.data.data.user_name,
					user_mobile:response.data.data.mobile,
					order_number:response.data.data.order_number,
					date:response.data.data.created_at,
					store_image:response.data.data.store_image,
				});
				
				const  tempArr = response.data.data.coupons.map(item => ({...item, status_chk: 1}))
				setCoupon(tempArr);
				
			}                  
		}).catch(error => {
			 
		});  
	 /* End get order */
	 
	 /* Start get profile */
	let formData1 = new FormData(); 
		formData1.append('token', localStorage.getItem("token"));
		API.post('get_profile', formData1)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setDisaddress(response.data.data.user_address);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get profile */
	  
},[]) 

/* Start Value set */
const handleInputChange = (event) => {
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value})); 
}
const handleInputChange1 = (id,title,street_name,state_id,city_id,zip_code) => {
	if(inputs.address===id)
	  {
		   var lbl_add1='address';
		setInputs(inputs => ({...inputs, [lbl_add1]: 0})); 
		setOrderaddress('');
	  }else{
	  var lbl_add='address';
      setInputs(inputs => ({...inputs, [lbl_add]: id})); 
	  var html='';
	if(street_name!=='')
	{
		html+=street_name+', ';
	}
	/*if(state_id!=='')
	{
		html+=state_id+', ';
	}
	if(city_id!=='')
	{
		html+=city_id+', ';
	}*/
	if(zip_code!=='')
	{
		html+=zip_code;
	}
	  
	  setOrderaddress(html);
	  }
}
const handleInputChangeTips = (event) => {
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
	   var tips=event.target.value;
	   tips = (typeof tips !== 'undefined' && tips !== '' && !isNaN(tips)) ? parseFloat(tips) : 0;
	   var garnd_total=inputs.garnd_total;
	   
	   garnd_total = (typeof garnd_total !== 'undefined' && garnd_total !== '' && !isNaN(garnd_total)) ? parseFloat(garnd_total) : 0;
	   
	   var total=parseFloat(tips+garnd_total);
	   total=total.toFixed(2);
	   var payable_total_lbl='payable_total';
	   setInputs(inputs => ({...inputs, [payable_total_lbl]: total}));
}
/* End Value set */
const handleSubmitBook = () => {
	const validationErrors = validateorderser(inputs);
        const noErrors = Object.keys(validationErrors).length === 0;
		setError('');
		setErrors({});
		console.log(key);
        setErrors(validationErrors);
		
		if(noErrors){
			var add11=1;
			var address=1;
			if(inputs.add_status===true)
			{
			   address = (typeof inputs.address !== 'undefined' && !isNaN(inputs.address) && inputs.address !== '') ? parseFloat(inputs.address) : 0;
				 add11=address;
			}else{
				address = (typeof inputs.address !== 'undefined' && !isNaN(inputs.address) && inputs.address !== '') ? parseFloat(inputs.address) : 0;
			}
			if(add11===0)
			{
				//alert('Please Select address');
				gettoastwarning('Please add or select an address for merchant’s service location','');
			}else{
				let formData = new FormData();
				formData.append('token', localStorage.getItem("token"));
				formData.append('emp_id', localStorage.getItem("book_emp_id"));
				formData.append('start_date', localStorage.getItem("book_start_date"));
				formData.append('end_date', localStorage.getItem("book_end_date"));
				formData.append('savings_total', inputs.saving_amount);
				formData.append('sales_tax_rate_per', inputs.sales_tax_rate_per);
				formData.append('sales_tax_rate', inputs.sales_tax_rate);
				formData.append('amount', inputs.total_due);
				formData.append('store_id', localStorage.getItem("book_store_id"));
				formData.append('address', inputs.address); 
				formData.append('coupon_id', JSON.stringify(couponid));
				formData.append('agree', inputs.agree);
				API.post('service_booked_order', formData)
					.then(response => {
					if(response.data.status === 0){
						setError(response.data.message);
						setLoading(false); 
					}else{
						 localStorage.removeItem("book_emp_id");
						 localStorage.removeItem("book_start_date");
						 localStorage.removeItem("book_end_date");
						 localStorage.removeItem("book_store_id");
						 localStorage.removeItem("book_type");
						setLoading(false);
						setShow(false);
						setShow3(true);
						//navigate('/order-payment',{ state: { message:response.data.message } });
					   //navigate('/order-confirmation',{ state: { message:response.data.message } });
					}                  
				}).catch(error => {
					 setLoading(false);
					  setError("Something went wrong. Please try again later.");
				});
			}
		}
}

const handleClose2 = () => setShowadd(false);
const handleShowAdd = () => setShowadd(true);

/* Start Address Submit profile */
const handleAddressSubmit = (event) => {
	event.preventDefault();
		const validationErrors = validateaddressprofile(address);
        const noErrors = Object.keys(validationErrors).length === 0;
		setErrors({});
        setErrors(validationErrors);
		
		if(noErrors){
			setLoading(true);
			let title=address.title;
			let street_name=address.street_name;
			let state_id=address.state_id;
			let city_id=address.city_id;
			let latitude=address.latitude;
			let longitude=address.longitude;
			let zip_code=address.zip_code;

			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			formData.append('title', title);
			formData.append('street_name', street_name);
			formData.append('state_id', state_id);
			formData.append('city_id', city_id);
			formData.append('latitude', latitude);
			formData.append('longitude',longitude);
			formData.append('zip_code', zip_code);
			
			
			API.post('add_address', formData)
                .then(response => {
                if(response.data.status === 0){
                    //alert(response.data.message);
					gettoastwarning(response.data.message,'');
                    setLoading(false); 
					setShowadd(false);
                }else{
					setDisaddress(response.data.data);
					setLoading(false);
				    setShowadd(false);
					setAddress({title:'',street_name:'',state_id:'',city_id:'',latitude:'',longitude:''});
					setAddress1("");
					
                }                  
            }).catch(error => {
                 setLoading(false);
				 //alert("Something went wrong. Please try again later.");
				 gettoastwarning("Something went wrong. Please try again later.",'');
				 setShowadd(false);
			});  
		}
}
/* End Address Submit profile */	
/* Start Address Value set */
const handleAddressInputChange = (event) => {
      event.persist();
      setAddress(address => ({...address, [event.target.name]: event.target.value})); 
}
/* End Address Value set */
/* Start get lat lng */
 const handleSelect = async (addres) => {
	 let add='street_name';
	setAddress(address => ({...address, [add]: addres}));
    setAddress1(addres);
	 try{
      const results = await geocodeByAddress(addres);
	  console.log(results);
	  const postalCodeComponent = results[0].address_components.find(component => component.types.includes('postal_code'));
        if (postalCodeComponent) {
          
		  let zip_codelbl='zip_code';
		  let zip_code=postalCodeComponent.short_name;
			setAddress(address => ({...address, [zip_codelbl]: zip_code}));
        }
		let city_lbl='city_id';
		let state_lbl='state_id';
		setAddress(address => ({...address, [city_lbl]: ''}));
		setAddress(address => ({...address, [state_lbl]: ''}));
		for (var i = 0; i < results[0].address_components.length; i++) {
			
			  for (var j = 0; j < results[0].address_components[i].types.length; j++) {
				 
				if (results[0].address_components[i].types[j] === "locality") {
				  
				  let city=results[0].address_components[i].long_name;
					setAddress(address => ({...address, [city_lbl]: city}));
				}
				if (results[0].address_components[i].types[j] === "administrative_area_level_1") {
				  
				  let state=results[0].address_components[i].long_name;
					setAddress(address => ({...address, [state_lbl]: state}));
				}
			  }
		}
		
	  
      const latLng = await getLatLng(results[0]);
      let latitudelbl='latitude';
	  let longitudelbl='longitude';
	  let lat=latLng.lat;
	  let lng=latLng.lng;
	 setAddress(address => ({...address, [latitudelbl]: lat}));
	 setAddress(address => ({...address, [longitudelbl]: lng}));
    } catch (error) {
    }
 }
/* End get lat lng */

const placeorder = () => {
	var address=1;
		if(inputs.add_status===true)
		{
		   address = (typeof inputs.address !== 'undefined' && !isNaN(inputs.address) && inputs.address !== '') ? parseFloat(inputs.address) : 0;
		}
		if(address===0)
		{
			//alert('Please Select address');
			gettoastwarning('Please add or select an address for merchant’s service location','');
		}else{
			setShow(true);
		}
}

const coupon_chk = (val) => {
		setLoading(true);

	let formData = new FormData();
		formData.append('token', localStorage.getItem("token"));
		formData.append('coupon_id', val.id);
		formData.append('order_id', 0);
		formData.append('amount', inputs.garnd_total);
		formData.append('save', JSON.stringify(couponid));
		
		API.post('user_check_coupon_valid_or_not', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				//alert(response.data.message);
				gettoastwarning(response.data.message,'');
			}else{
				setCouponid([
				  ...couponid,
				  { id: val.id,assign_id:val.assign_id, discount: response.data.data.old_save }
				]);
				
				let updateData = coupon.map((item, i) => {
					var it='';
					if(val.id===item.id)
					{
						it= { ...item, status_chk: 3 };	
					}else{
						if(couponid.length===1){
							if(item.status_chk!==3)
							{
							  it= { ...item, status_chk: 2 };
							}else {
								it=item;
							}
						}else{
							if(val.double_coupon_not_allowed===1)
							{
								if(item.status_chk!==3)
								{
									it= { ...item, status_chk: 2 };
								}else {
									it=item;
								}
							}else{
								if(item.double_coupon_not_allowed===1)
								{
									if(item.status_chk!==3)
									{
									  it= { ...item, status_chk: 2 };
									}
								}else{
									if(item.status_chk!==3)
									{
										it= { ...item, status_chk: 1 };
									}
								}
							}
						}
					}
					return it;
				});
				setCoupon(updateData);
				
				coupon_cal(response.data.data.main_save);
				//alert('Coupon accepted successfully !');
				gettoastsuccess('Coupon accepted successfully','');
			}                  
		}).catch(error => {
			 
		});  
		
}

const coupon_cal = (discount) => {
	var dis=parseFloat(discount);
	var garnd_total=inputs.garnd_total;
	var total=garnd_total-dis;
	
	var sales_tax_rate_per=parseFloat(inputs.sales_tax_rate_per);
	var sales_tax_rate=parseFloat(total)*sales_tax_rate_per/100;
	
	var total_due=total+parseFloat(sales_tax_rate);
	
	
	var savings_total_lbl='saving_amount';
	dis=dis.toFixed(2);
	  setInputs(inputs => ({...inputs, [savings_total_lbl]: dis}));
	
	
	var sales_tax_rate1=sales_tax_rate.toFixed(2);
	
	var sales_tax_rate_lbl='sales_tax_rate';
	 setInputs(inputs => ({...inputs, [sales_tax_rate_lbl]: sales_tax_rate1}));
	 
	 total_due=total_due.toFixed(2);
	 var total_due_lbl='total_due';
	 setInputs(inputs => ({...inputs, [total_due_lbl]: total_due}));
	
	/*var dis=parseFloat(discount);
	var order_total=inputs.garnd_total;
	var tips=inputs.tips;
	var garnd_total=order_total-dis;

	var savings_total_lbl='savings_total';
	dis=dis.toFixed(2);
	  setInputs(inputs => ({...inputs, [savings_total_lbl]: dis}));
	 
	var sales_tax_rate_per=parseFloat(inputs.sales_tax_rate_per);
	var sales_tax_rate=garnd_total*sales_tax_rate_per/100;
	
	var sales_tax_rate1=sales_tax_rate.toFixed(2);
	
	var sales_tax_rate_lbl='sales_tax_rate';
	 setInputs(inputs => ({...inputs, [sales_tax_rate_lbl]: sales_tax_rate1}));
	
	 var payable_total=parseFloat(garnd_total)+parseFloat(tips)+parseFloat(sales_tax_rate);
	 payable_total=payable_total.toFixed(2);
	 var payable_total_lbl='payable_total';
	  setInputs(inputs => ({...inputs, [payable_total_lbl]: payable_total}));*/
}

const handleCheckboxChange = (event) => {
	var val_agree=event.target.checked ? 1 : 0;
	var agree_lbl='agree';
	  setInputs(inputs => ({...inputs, [agree_lbl]: val_agree}));
    
  };

    return (
        <>
        {/* ========== User Order Summary Page Start ========== */}
            <section className="gen-wrap">
			<ToastContainer />
            {/* ========== Navbar Start ========== */}
               <Navbar headerText={title}/>
                <Col className="thstbox">
                                {step === 0 ? (
                                  <h3>Contact Setup</h3>
                                    ) : undefined}
                                {step === 1 ? (
                                  <h3>Apply Coupon</h3>
                                    ) : undefined} 
                                {step === 2 ? (
                                  <h3>Pay the Bill</h3>
                                    ) : undefined}                  
                </Col>                
            {/* ========== Navbar End ========== */}
             <Container>
                <Row className="">
                  <Col md={12} lg={12} xl={12} xxl={12} className="usrsmrypg px-0">
                  {/* ========== Order Summary content Start ========== */}
                   <ul className="hmtp-scldv">
                      <Row className="mx-0 ucl-sfbtn">
                         <div className="px-0" style={{display: "flex",flexDirection: "column",justifyContent: "center",}}>
                     <Stepper value={step} items={steps} />
                     <Form initialValues={formState} onSubmitClick={onStepSubmit} render={(formRenderProps) => (
                         <div md={11} xs={11} style={{margin: "0px 15px",}}>
                           <FormElement>
                             {/*stepPages[step]*/}
							 
							 {error && (
							<div className="alert alert-danger">
							  {error}
							</div>)}
							{errors.order && (<p className="text-danger">{errors.order}</p>)}
							{step === 0 ? ( 
							<div className="cntstp-smrypg">
								<h3>Contact Setup</h3>
								<div className="mb-3" controlId="exampleForm.ControlInput1">
									<label className="form-label">User Name</label>
									<input type="text" className="form-control" placeholder="ABCDEF" value={inputs.user_name} disabled/>
								</div>
								<div className="mb-3" controlId="exampleForm.ControlInput1">
									<label className="form-label">Phone Number</label>
									<input type="tel" className="form-control" placeholder="123456789" value={inputs.mobile} disabled/>
								</div>
								<div className="mb-3 ckbox" controlId="">
									<div key="default-1" className="mb-3">
										<input type="checkbox" id="default-1" label="I consent to share my phone number with the merchant when ordering." checked={inputs.agree === 1}
          onChange={handleCheckboxChange}/> I consent to share my phone number with the merchant when ordering.
									</div>
								</div>
								
								<div>
								{disaddress.length >0 ? (
								<div className="mb-3 mt-3">
								<label className="form-label mb-4">Please provide or select an address when you require the merchant to visit.</label>
								{disaddress.length > 0 && disaddress.map((item,index) => {
									return(
									<div className="form-check d-flex align-items-center mb-3">
									
									  <input className="form-check-input form-check-input-add me-2" type="radio" name="address" id={`flexRadioDefault1${index}`} value={item.id} onClick={() =>handleInputChange1(item.id,item.title,item.street_name,item.state_id,item.city_id,item.zip_code)} checked={inputs.address === item.id} />
									   
										  <label className="form-check-label ordr-smry" for={`flexRadioDefault1${index}`}>
											<p>{item.title} {item.street_name}</p>
											<p>{item.state_id} {item.city_id}</p>
											<p>{item.zip_code}</p>
										  </label>
										 
								   </div>
								  )
								})} 
								
								</div>
								) : (
								<div className="mb-1 mt-3">
								<label className="form-label mb-1">Please provide or select an address when you require the merchant to visit.</label>
								</div>
							   )}
<Row className="mx-0">
									<p className="text-center mt-4"><NavLink to="#" onClick={handleShowAdd}><Button variant="primary" className="btn-ord-add" type="button">Or Add Address</Button> </NavLink></p>
								</Row>							   
							   </div>
								{coupon.length >0 &&(
								<div className="mb-3 mt-3">
								<label className="form-label mb-4">Select Coupon</label>
							  <Row>
							  
								<Col md={12} xs={12}>
									<ul className="sldr-add">
										{coupon.length > 0 && coupon.map((item,index) => {
							        return(<li className="sldr-in-add">
										<div className="row mincrd">
										<Col md={9} xs={3} className="align-self-center mb-2">
											<h4>{item.coupon_type}</h4>
										   </Col>
											<Col md={3} xs={4} className="align-self-center text-end mb-2">
											{(() => {
													if (item.status_chk===1) {
														return (
															<Button className="btn" onClick={() =>coupon_chk(item)}>Apply</Button>
														)
													}else if (item.status_chk===2) {
															return (
																<Button className="btn" disabled>Apply</Button>
															)
													}else if (item.status_chk===3) {
															return (
																<Button className="btn">Applied</Button>
															)
														}
												})()}
											
											</Col>
											<Col md={12}>
												{(() => {
												if (item.coupon_type==='Buy & Get') {
													return (<p>Buy {item.buy_quantity} get {item.get_quantity} free,for {item.product_item_name}</p>)
												}else if(item.coupon_type==='Discount Offer'){
													return (  <p>{item.discount_percentage} % Discount When Purchase Over ${item.minimum_purchase_amount}</p>)
												}else if(item.coupon_type==='Loyalty Setup'){
													return (<p>Buy {item.buy_quantity_needed} {item.product_applicable_item_if_any} get {item.product_get_item_name} free</p>)
												}else if(item.coupon_type==='Invitation'){
													return (<p>{item.product_item_name}</p>)
												}else if(item.coupon_type==='Welcome coupon'){
													return (<p>{item.discount_percentage}% for all new members joining your membership has been set up</p>)
												}								
												})()}
											   {(() => {
												if (item.coupon_type!=='Welcome coupon') {
													return (<p>{item.description}</p>)
												}									
												})()}	
											</Col>
											</div>
										</li>
										)})}
									</ul>
								</Col>
								
								</Row>
								</div>
							  )}
							</div>
						   ) : ''}
						   {step === 1 ? ( 
						   <Row className="ordr-smrypg mx-0">
						   {orderdata.length > 0 && orderdata.map((item,index) => {
							return(
							  <Row className="mx-0 tpord-sum">
								<Col md={8} xs={8} className=" mb-3"><h3>{item.product_name}</h3></Col>
								<Col md={4} xs={4} className="text-end mb-3">
								  <h3>${item.price} X {item.quantity}</h3>
								  <p className="linetxt colrchng">$69.99</p>
								</Col>
								<Col md={3} xs={3} className="colrchng">Date:</Col>
								<Col md={9} xs={9}><p>{item.created_at}</p></Col>
								
							  </Row>
							 )
							})} 
							  <Col md={12} xs={12} className="px-0 ordrsmrysld">
							  <h3>From Coupons Box</h3>
								   <OwlCarousel items={1.2}  className='owl-theme' loop={false} margin={20} center>
									  <Col className="crdordrsmry">
										 <Row className="mx-0 mincrd">
										   <Col md={9} xs={8} className="align-self-center mb-2">
										   <h4>Get discount code of 25% Off</h4>
										   </Col>
										   <Col md={3} xs={4} className="align-self-center text-end mb-2"><Button variant="">Apply</Button>{' '}</Col>
										   <Col md={12} xs={12} ><p>Get discount code of 25% Off value | All saloon deals T&C</p></Col>
										 </Row>
									  </Col> 
									  <Col className="crdordrsmry">
										 <Row className="mx-0 mincrd">
										   <Col md={9} xs={8} className="align-self-center mb-2">
										   <h4>Get discount code of 25% Off</h4>
										   </Col>
										   <Col md={3} xs={4} className="align-self-center text-end mb-2"><Button variant="">Apply</Button>{' '}</Col>
										   <Col md={12} xs={12} ><p>Get discount code of 25% Off value | All saloon deals T&C</p></Col>
										 </Row>
									  </Col>  
								   </OwlCarousel>
							   </Col>
							   <Col className="dwnordsmry">
								 <h3 className="">Estimate price</h3>
								 <div className="line"></div>
								 <Table responsive>
								  <thead>
									<tr>
									  
									</tr>
								  </thead>
								  <tbody>
									<tr className="tp-lst">
									  <td>Order Total</td>
									  <td><h6>${inputs.garnd_total}</h6></td>
									</tr>
									<tr className="tp-lst line">
									  <td>Savings</td>
									  <td><h6 className="grnclr">-$20.99</h6></td>
									</tr>
									<tr className="cnt-lst">
									  <td>SubTotal</td>
									  <td><h6>${inputs.garnd_total}</h6></td>
									</tr>
									<tr className="cnt-lst">
									  <td>Tips</td>
									  <td><h6>-</h6></td>
									</tr>
									<tr className="cnt-lst line">
									  <td>Tax</td>
									  <td><h6>$5.99</h6></td>
									</tr>
									<tr className="dwn-lst line">
									  <td>Total payable</td>
									  <td><h6 className="bluclr">${inputs.garnd_total}</h6></td>
									</tr>
								  </tbody>
								</Table>
							   </Col>
							  </Row>
						      ) : ''}
							  
							 {step === 2 ? (  
							 <Row className="mx-0 pybl-smrypg">
								<Col md={12} xs={12} className=" mb-3 shwbx-pyblsmry">
								  <Row>
									<Col md={6} xs={6} className="align-self-center low"><p>Conf. No:</p></Col>
									<Col md={6} xs={6} className="align-self-center text-end"><p>1234zvsdvsvdvff</p></Col>
								  </Row>
								</Col>
								<Col md={12} xs={12} className=" mb-3 shwbx-pyblsmry">
								<h3>Invoice</h3>
								<div className="line specer"></div>
								  <Row className="mx-0">
									<Col md={6} xs={6} className="align-self-center low"><p>Tax</p></Col>
									<Col md={6} xs={6} className="align-self-center text-end"><p>$5.99</p></Col>
									<Col md={6} xs={6} className="align-self-center low"><p>Tips</p></Col>
									<Col md={6} xs={6} className="align-self-center text-end"><input type="text" className="form-control" placeholder="-" name="tips" value={inputs.tips} onChange={handleInputChangeTips} /></Col>
									<div className="line specer col-12"></div>
									<Col md={6} xs={6} className="align-self-center low"><p>Total Payable</p></Col>
									<Col md={6} xs={6} className="align-self-center text-end"><p className="bluclr">${inputs.payable_total}</p></Col>
								  </Row>
								</Col>
								<Col md={12} xs={12} className=" mb-3 shwbx-pyblsmry">
								  <Row>
									<Col md={12} xs={12} className="">
									  <Tab.Container id="left-tabs-example" defaultActiveKey={key} onSelect={(k) => setKey(k)}>
											 <Nav variant="pills" className="flex-column">
											  <Row>
												<Col>
												  <Nav.Item>
												   <Nav.Link eventKey="first">Cash</Nav.Link>
												  </Nav.Item>
												</Col>
												<Col>
												  <Nav.Item>
												   <Nav.Link eventKey="second">Credit Card </Nav.Link>
												  </Nav.Item>
												</Col>
												<Col>
												  <Nav.Item>
												   <Nav.Link eventKey="third">Debit Card</Nav.Link>
												  </Nav.Item>
												</Col>                    
											  </Row>
											  </Nav>
											  <Tab.Content>
												<Tab.Pane eventKey="first">
												  
												</Tab.Pane>
												<Tab.Pane eventKey="second">
												  <Row xs={{ gutterX: 1 }} className="mx-0">
													<Col md={12} xs={12} >
													 <div className="mb-3">
														<input type="text" className="form-control" placeholder="Cardholder Name" name="credit_card_name" value={inputs.credit_card_name} onChange={handleInputChange}/>
														{errors.credit_card_name && (<p className="text-danger">{errors.credit_card_name}</p>)}
													 </div>
													</Col>
													<Col md={12} xs={12} >
													 <div className="mb-3">
														<input type="text" className="form-control" placeholder="Card Number" name="credit_card_number" value={inputs.credit_card_number} onChange={handleInputChange} maxLength={16}/>
													    {errors.credit_card_number && (<p className="text-danger">{errors.credit_card_number}</p>)}
													 </div>
													</Col>                        
													<Col md={6} xs={6} className="">
													<select aria-label="Default select example" className="mb-3 form-control" name="credit_card_month" value={inputs.credit_card_month} onChange={handleInputChange}>
														<option defaultValue="">Month</option>
														<option defaultValue="1">01</option>
														<option defaultValue="2">02</option>
														<option defaultValue="3">03</option>
														<option defaultValue="4">04</option>
														<option defaultValue="5">05</option>
														<option defaultValue="6">06</option>
														<option defaultValue="7">07</option>
														<option defaultValue="8">08</option>
														<option defaultValue="9">09</option>
														<option defaultValue="10">10</option>
														<option defaultValue="11">11</option>
														<option defaultValue="12">12</option>
													</select>
													{errors.credit_card_month && (<p className="text-danger">{errors.credit_card_month}</p>)}
													</Col>
													<Col md={6} xs={6} className="">
													<select aria-label="Default select example" className="mb-3 form-control" name="credit_card_year" value={inputs.credit_card_year} onChange={handleInputChange}>
														<option defaultValue="">Year</option>
														<option defaultValue="1">2022</option>
														<option defaultValue="2">2023</option>
														<option defaultValue="3">2024</option>
														<option defaultValue="4">2025</option>
														<option defaultValue="5">2026</option>
														<option defaultValue="6">2027</option>
													</select>
													{errors.credit_card_year && (<p className="text-danger">{errors.credit_card_year}</p>)}
													</Col>
													<Col md={6} xs={6} className="">
													 <div className="mb-3">
														<input type="text" className="form-control" placeholder="CVV" name="credit_card_cvv" value={inputs.credit_card_cvv} onChange={handleInputChange}/>
														{errors.credit_card_cvv && (<p className="text-danger">{errors.credit_card_cvv}</p>)}
													 </div>
													</Col>
													<Col md={12}>
													  <p>Note: There is no card details you added. <NavLink to="/payment-after-login">CLICK HERE</NavLink> to add your card details.</p>
													</Col>                        
												  </Row>
												</Tab.Pane>
												<Tab.Pane eventKey="third">
												  <Row xs={{ gutterX: 1 }} className="mx-0">
													<Col md={12} xs={12} >
													 <div className="mb-3">
														<input type="text" className="form-control" placeholder="Cardholder Name" name="debit_card_name" value={inputs.debit_card_name} onChange={handleInputChange}/>
													    {errors.debit_card_name && (<p className="text-danger">{errors.debit_card_name}</p>)}
													 </div>
													</Col>
													<Col md={12} xs={12} >
													 <div className="mb-3">
														<input type="text" className="form-control" placeholder="Card Number" name="debit_card_number" value={inputs.debit_card_number} onChange={handleInputChange} maxLength={16}/>
														{errors.debit_card_number && (<p className="text-danger">{errors.debit_card_number}</p>)}
													 </div>
													</Col>                        
													<Col md={6} xs={6} className="">
													<select aria-label="Default select example" className="mb-3 form-control" name="debit_card_month" value={inputs.debit_card_month} onChange={handleInputChange}>
														<option value="">Month</option>
														<option defaultValue="1">01</option>
														<option defaultValue="2">02</option>
														<option defaultValue="3">03</option>
														<option defaultValue="4">04</option>
														<option defaultValue="5">05</option>
														<option defaultValue="6">06</option>
														<option defaultValue="7">07</option>
														<option defaultValue="8">08</option>
														<option defaultValue="9">09</option>
														<option defaultValue="10">10</option>
														<option defaultValue="11">11</option>
														<option defaultValue="12">12</option>
													</select>
													{errors.debit_card_month && (<p className="text-danger">{errors.debit_card_month}</p>)} 
													</Col>
													<Col md={6} xs={6} className="">
													 <select aria-label="Default select example" className="mb-3 form-control" name="debit_card_year" value={inputs.debit_card_year} onChange={handleInputChange}>
														<option value="">Year</option>
														<option defaultValue="1">2022</option>
														<option defaultValue="2">2023</option>
														<option defaultValue="3">2024</option>
														<option defaultValue="4">2025</option>
														<option defaultValue="5">2026</option>
														<option defaultValue="6">2027</option>
													</select>
													{errors.debit_card_year && (<p className="text-danger">{errors.debit_card_year}</p>)} 
													</Col>
													<Col md={6} xs={6} className="">
													 <div className="mb-3">
														<input type="text" className="form-control" placeholder="CVV" name="debit_card_cvv" value={inputs.debit_card_cvv} onChange={handleInputChange}/>
														{errors.debit_card_cvv && (<p className="text-danger">{errors.debit_card_cvv}</p>)} 
													 </div>
													</Col>
													<Col md={12}>
													  <p>Note: There is no card details you added. <NavLink to="/payment-after-login">CLICK HERE</NavLink> to add your card details.</p>
													</Col>
												  </Row>
												</Tab.Pane>
											  </Tab.Content>
											</Tab.Container>
									</Col>
								  </Row>
								</Col>
								</Row>
							   ) : ''}
							 
                             <span style={{marginTop: "40px",}} className={"k-form-separator"}/>
                             <div style={{justifyContent: "space-between",alignContent: "center",}}
                               className={"k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"}>
                               <span className="d-none" style={{alignSelf: "center",}}>
                                 Step {step + 1} of 3
                               </span>
                               <div className="btnnxt-smrypg">
                                 {step !== 0 ? (
                                   <Button className="d-none" style={{marginRight: "16px",}} onClick={onPrevClick}> Previous </Button>
                                 ) : undefined}
								 {step === 0 ? (
                                 <Button themecolor={"primary"}  onClick={placeorder}> {isLastStep ? "Pay The Bill" : "Next"}</Button>
								) : ''}
								{step === 1 ? (
									<Button themecolor={"primary"} >Next</Button>
								) : ''}
								{step === 2 ? (
									<Button themecolor={"primary"} onClick={placeorder}>Pay The Bill</Button>
								) : ''}
                               </div>
                             </div>
                           </FormElement>
                         </div>
                       )}
                     />
                   </div>
                      </Row>
                   </ul>
                  {/* ========== Order Summary content End ========== */}
                   </Col>
                </Row>
				{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
			   
			   {/* ========== Address Popup Content Start ========== */}
                     <Modal show={showadd} onHide={handleClose2} className="popupwrngcnt">
                        <Modal.Header closeButton>
                           <h3 className="mdlttltxt">Add Address</h3>
                        </Modal.Header>
                        <Modal.Body>
							<form method="post" onSubmit={handleAddressSubmit} enctype="multipart/form-data">
							   <Col md={12} xs={12} className="ppinpt">
								<div className="mb-3" controlId="exampleForm.ControlInput1">
									 <lable className="form-label">Title <span>*</span></lable>
									 <input type="text" className="form-control" name="title" placeholder="ex Home " value={address.title} onChange={handleAddressInputChange} />
									 {errors.title && (<p className="text-danger">{errors.title}</p>)}
								  </div>
								   <div className="mb-3" controlId="exampleForm.ControlInput1">
										 <lable className="form-label">Address <span>*</span></lable>
										 <PlacesAutocomplete value={address1} onChange={setAddress1}  onSelect={handleSelect} searchOptions={searchOptions}>
										  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
											<div>
											  <input type="location" className="form-control" {...getInputProps({ placeholder: 'Enter address' })} placeholder="Name and street name" />
											  <div>
												{loading && <div>Loading...</div>}
												{suggestions.map((suggestion) => {
												  const style = {
													backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
												  };
												  return (
													<div {...getSuggestionItemProps(suggestion, { style })}>
													  {suggestion.description}
													</div>
												  );
												})}
											  </div>
											</div>
										  )}
										</PlacesAutocomplete>
										 {errors.street_name && (<p className="text-danger">{errors.street_name}</p>)}
									  </div>
									<div className="mb-3" controlId="exampleForm.ControlInput1">
									 <lable className="form-label">City <span>*</span></lable>
									 <input type="text" className="form-control" name="city_id" placeholder="city" value={address.city_id} onChange={handleAddressInputChange} />
									 {errors.city_id && (<p className="text-danger">{errors.city_id}</p>)}
								  </div>
								   <div className="mb-3" controlId="exampleForm.ControlInput1">
									 <lable className="form-label">State <span>*</span></lable>
									 <input type="text" className="form-control" name="state_id" placeholder="state" value={address.state_id} onChange={handleAddressInputChange}/>
									{errors.state_id && (<p className="text-danger">{errors.state_id}</p>)}
								  </div>
								  
								  <div className="mb-3" controlId="exampleForm.ControlInput1">
									 <lable className="form-label">Zip code <span>*</span></lable>
									 <input type="text" className="form-control" name="zip_code" placeholder="Zip code" value={address.zip_code} onChange={handleAddressInputChange}/>
									 {errors.zip_code && (<p className="text-danger">{errors.zip_code}</p>)}
								  </div>
							   </Col>
							   <Col md={12} xs={12} className="mb-3 text-center">
								<div className="mb-3 btn-dv"><button type="submit" className="btn">Save and Submit</button></div>
							   </Col>
							</form>  
                        </Modal.Body>
                     </Modal>
             {/* ========== Address Popup Content End ========== */}
			 
			 {/* ========== Order confirm Order Details Popup Start ========== */}
               <Modal show={show} onHide={handleClose} className="popupwrngcnt" backdrop="static">
                  <Modal.Header closeButton>
                     <h3 className="mdlttltxt">Order Details</h3>
                  </Modal.Header>
                  <Modal.Body>
                     <Col md={12} xs={12} className="" id="printablediv">
                     <Row className="mx-0 maincnt-recepts">
                  <Col md={10} xs={8}>
                  <h3>{inputsc.store_name}</h3>
                  <p>{inputsc.store_address} , {inputsc.store_zip}</p>
                  <p>Phone: {inputsc.store_mobile}</p>
				  <div className="left-cnt-rcptr">
					<h4 className="">Customer Name: <span>{inputsc.user_name}</span></h4>
						{inputs.agree===1 ?
 					 <h4>Contact No: <span>{inputsc.user_mobile}</span></h4>
					 : null }
					 {orderaddress!=='' ?
					<h4>Customer address: <span>{orderaddress}</span></h4>
						: null }
				  </div>
                  <div className="dwncmt">
                     <h5>Information:</h5>
					 <h4>Schedule date & time:<span>{moment(localStorage.getItem("book_start_date")).format("DD MMMM YYYY h:mm A")}</span></h4>
                     <h4>Invoice No.<span>{inputsc.order_number}</span></h4>
                     <h4>Invoice Date: <span>{inputsc.date}</span></h4>
					 {localStorage.getItem("book_emp_name")!=='' ?
					<h4>Employee Name: <span>{localStorage.getItem("book_emp_name")}</span></h4>
						: null }
                  </div>
                  </Col>
                  <Col md={2} xs={4} className="text-end left-cnt-rcpt">
                  <img src={inputsc.store_image} className="img-fluid" alt="Hm Img"/>
					  
                  </Col>
                  <Col md={12} xs={12} className="tblinvcpg">
                  {/* ========== Table Content Start ========== */}
                  <Table responsive>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Service/Items</th>
                           <th>Price</th>
                           <th>Q’ty</th>
                           <th>Amount</th>
                        </tr>
                     </thead>
                     <tbody>
					  {orderdata && orderdata.map((val,index) => {
						return(
                        <tr>
					  <td>{index+1}</td>
                           <td>{val.product_name}</td>
                           <td>${val.price}</td>
                           <td>{val.quantity}</td>
                           <td>${val.total}</td>
                        </tr>
                        )
					  })}  
                     </tbody>
                  </Table>
                  {/* ========== Table Content End ========== */}
                  <div className="text-end tbldwndv">
                  <Stack direction="horizontal" gap={0}>
					<div className="pb-1 ms-auto">
					   <p>Subtotal</p>
					</div>
					<div className="pb-1"><span>${inputs.subtotal}</span></div>
				 </Stack>
				 <Stack direction="horizontal" gap={0}>
					<div className="pb-1 ms-auto">
					   <p>Coupon or discount</p>
					</div>
					<div className="pb-1">-<span>${inputs.saving_amount}</span></div>
				 </Stack>
				 <Stack direction="horizontal" gap={0}>
					<div className="pb-1 ms-auto">
					   <p>Tax ({inputs.sales_tax_rate_per}%)</p>
					</div>
					<div className="pb-1"><span>${inputs.sales_tax_rate}</span></div>
				 </Stack>
				 <Stack direction="horizontal" gap={0}>
					<div className="pb-1 ms-auto">
					   <p>Gratuity</p>
					</div>
					<div className="pb-1 ps-3"><span> {inputs.tips} </span></div>
				 </Stack>
				 <Stack direction="horizontal" gap={4} className="lstprcdv">
					<div className=" ms-auto">
					   <h6>Total Due</h6>
					</div>
					<div className="">
					   <h6>${inputs.total_due}</h6>
					</div>
				 </Stack>
				 </div>
                  </Col>
                 
               </Row>
                     </Col>
                     <Col className="apmnt-ordrlstbtn">
                     <Row className="rwd mt-3">
                        
                        <Col md={6} xs={6}>
                        <NavLink to={`tel:${inputsc.mobile}`}>
                           <Button variant="black" type="submit">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 18" fill="none">
                                 <path d="M2.69273 1.03486C2.33907 1.1444 2.24831 1.21951 1.29688 2.17407C0.42056 3.05039 0.361096 3.11299 0.242167 3.3571C0.173313 3.49794 0.0888113 3.72328 0.0575143 3.85786C-0.0175986 4.19274 -0.0207283 4.89692 0.0575143 5.32256C0.454987 7.50084 2.03862 10.086 4.46101 12.5084C6.90844 14.9589 9.51235 16.5676 11.6437 16.9526C11.9598 17.0089 12.0975 17.0152 12.5043 17.0026C13.0458 16.9839 13.2336 16.9463 13.5997 16.771C13.8188 16.6646 13.9033 16.5895 14.7765 15.7163C15.5996 14.8932 15.7311 14.7492 15.8187 14.5709C16.0534 14.0889 16.0597 13.5756 15.8406 13.1124C15.7311 12.8808 15.6716 12.8151 14.5449 11.6821C13.8971 11.0312 13.2836 10.4365 13.1835 10.3614C12.9143 10.158 12.7078 10.086 12.3166 10.0703C11.9347 10.0547 11.7031 10.1173 11.4183 10.3051C11.1367 10.4897 11.0177 10.6525 10.8268 11.0969C10.7079 11.3692 10.5702 11.5569 10.3793 11.7009C9.9286 12.0452 9.4435 12.1234 8.90519 11.9419C7.53438 11.485 5.91945 9.99835 5.23092 8.56181C5.06191 8.20502 4.89917 7.6761 4.89917 7.48519C4.90543 6.99383 5.29038 6.42422 5.76609 6.2114C6.26059 5.98606 6.33257 5.94537 6.49845 5.7795C6.86149 5.41645 7.01485 4.84998 6.88653 4.35235C6.78012 3.94236 6.71753 3.86725 5.47816 2.61849C4.11674 1.24455 4.02285 1.16631 3.57843 1.03799C3.36874 0.978525 2.87738 0.975395 2.69273 1.03486Z" fill="#3F9534"/>
                              </svg>
                              Call
                           </Button>
                        </NavLink>
                        </Col>
						<Col md={6} xs={6} className="apmnt-btndvpop apmnt-btndvpopuser">
						 <NavLink to="#" onClick={() => handleSubmitBook()}><Button variant="rgt">Place Order</Button>{' '}</NavLink>
						</Col>
                     </Row>
                     </Col>
                     
                  </Modal.Body>
                  <Modal.Footer className="d-none"></Modal.Footer>
               </Modal>
      {/* ========== Order confirm Order Details Popup End ========== */}
	  <Modal show={show3} onHide={handleClose3} className="popupwrngcnt" backdrop="static">
                       <Modal.Header >
                          
                       </Modal.Header>
                       <Modal.Body>
                         <Row className="cnfmtnpg justify-content-center mx-0">
                           <Col md={12} xs={12} className="px-0 ">
                            <div className="text-center ">
                              <img src= {'assets/images/svg/confrmation-check.svg'} className="img-fluid mb-3" alt="Cnfrmation Img"/> 
                              <h3>Your Order has been placed; please check your Calendar for the pickup schedule.  You will receive the Pickup Notification and bill after the order has been done.</h3>
                            </div>
                           </Col>
                           <Col md={8} xs={8} className="btn-ordrcnfrmation btn-ordrcnfrmation text-center mt-3 mb-5">
                            <NavLink to="/order-payment"><Button variant="home">Done</Button>{' '}</NavLink>
                           </Col>
                         </Row>


                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>  
			 
             </Container>
          </section>
        {/* ========== User Order Summary Page End ========== */}
        </>
    )
}
export default UserOrdrsmrynew;