import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Button, Table, Stack } from 'react-bootstrap';
import { NavLink,useParams } from "react-router-dom";
import './MerSubreceipts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';

const MerSubreceipts = () => {
	const { id,sub_id } = useParams();
	const [loading,setLoading] = useState(true);
	const [ord, setOrd] = useState([]);	
	const [inputs,setInputs] = useState({user_name:'',mobile:'',address:'',invoice_no:'',current_period_start:'',current_period_end:'',image:'',sub_total:0,discount:0,grand_total:0,tax:0});
	
useEffect(() => {
      document.title = `Merchant Receipts | Blue Faucet`;
      document.getElementById('bodywrap').className='usrrcptcpgmain grnbg';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	
	/* Start get order data */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('id', id);
		formData.append('subscription_transaction_id', sub_id);
		API.post('get_subscription_history_detail', formData)
			.then(response => {
			setLoading(false);	
			if(response.data.status === 0){
				
			}else{
				setInputs({
					user_name:response.data.data.user_name,
					mobile:response.data.data.mobile,
					address:response.data.data.address,
					invoice_no:response.data.data.invoice_no,
					current_period_start:response.data.data.current_period_start,
					current_period_end:response.data.data.current_period_end,
					image:response.data.data.image,
					sub_total:response.data.data.sub_total,
					discount:response.data.data.discount,
					grand_total:response.data.data.grand_total,
					tax:response.data.data.tax
				});
			   setOrd(response.data.data.data_array);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get order data */
},[id,sub_id]) 

    return (
      
        <>
      {/* ========== Merchant Receipt Page Start ========== */}
         <section className="gen-wrap">
      {/* ========== Navbar Start ========== */}
         <Navbar headerText={'Receipts'}/>
      {/* ========== Navbar End ========== */}
         <Container>
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
               <Col md={12} xs={12} className="py-2">
      {/* ========== Receipt Content Start ========== */}
               <Row className="mx-0 maincnt-recepts">
                  <Col md={10} xs={8}>
                  <h3>BlueFaucet inc.</h3>
                  <p>522 W,Riverside Ave. #5689</p>
                  <p>Spokane, WA 99201</p>
				  <div className="left-cnt-rcptr mt-2">
					<h4>Customer Name: {inputs.user_name}</h4>
					<h4>Contact info: {inputs.mobile}</h4>
					<h4>Customer Address: {inputs.address}</h4>
				  </div>
				  
                  <div className="dwncmt">
                     <h5>Information: </h5>
					 <h4>Invoice No. {inputs.invoice_no}</h4>
                     <h4>Invoice Date: {inputs.current_period_start}</h4>
                     <h4>Next Billing Schedule: {inputs.current_period_end}</h4>
                  </div>
                  </Col>
                  <Col md={2} xs={4} className="text-end left-cnt-rcpt">
                  <img src={inputs.image} className="img-fluid ps-2" alt="Hm Img"/>
                  </Col>
                  <Col md={12} xs={12} className="tblinvcpg">
                  <Table responsive>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Service/iteam</th>
                           <th>Q'ty</th>
                           <th>Amount</th>
                        </tr>
                     </thead>
                     <tbody>
					 {ord && ord.map((val,index) => {
					   return(
                      <tr>
						<td>{index+1}</td>
						<td>{val.name}</td>
						<td>{val.qty}</td>
						<td>{val.total}</td>
                       </tr>
					  )
					  })}
                        
                     </tbody>
                  </Table>
                  <div className="text-end tbldwndv">
                     <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Subtotal</p>
                        </div>
                        <div className="pb-1"><span>${inputs.sub_total}</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Coupon Or Discount</p>
                        </div>
                        <div className="pb-1">-<span>${inputs.discount}</span></div>
                     </Stack>
					 <Stack direction="horizontal" gap={0}>
                        <div className="pb-1 ms-auto">
                           <p>Tax</p>
                        </div>
                        <div className="pb-1"><span>${inputs.tax}</span></div>
                     </Stack>
                     <Stack direction="horizontal" gap={4} className="lstprcdv">
                        <div className=" ms-auto">
                           <h6>Total Due</h6>
                        </div>
                        <div className="">
                           <h6>${inputs.grand_total}</h6>
                        </div>
                     </Stack>
                  </div>
                  </Col>
                  <Col md={6} xs={7} className="dwn-cntrecpt">
                   <h3>Payment Information:</h3>
				   <p>Charge to your default setting with recurring,</p>
                  </Col>
               </Row>
      {/* ========== Receipt Content End ========== */}
               <Col className="rcptbtn mt-3 d-none"><NavLink to="#"><Button variant="" type="submit"> Apply For Refund</Button></NavLink></Col>
               </Col>
               </Col>
            </Row>
			{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
         </Container>
         </section>
      {/* ========== Merchant Receipt Page End ========== */}
        </>
    )
}
export default MerSubreceipts;