import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './Coupon.css';
import './steper.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { TagsInput } from "react-tag-input-component";
import {validatecouponstep1,validatecouponstep2,validatecouponstep3} from '../../../Utils/validate';
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess,gettoastwarning } from '../../../Utils/Common';

const Coupon = () => {
const navigate = useNavigate();
const [selected, setSelected] = useState([]);

const [errors,setErrors] = useState({});

const [showtab1,setShowtab1] = useState(true);
const [showtab2,setShowtab2] = useState(false);
const [showtab3,setShowtab3] = useState(false);
const [showtab4,setShowtab4] = useState(false);

const [showdate,setShowdate] = useState(true);
//const [showdays,setShowdays] = useState(false);

const [inputs,setInputs] = useState({coupon_name:'',date_type:'Start & End',start_date:'',end_date:'',return_before:'',coupon_type:'Buy & Get',one_time_use_only:1,double_coupon_is_not_allowed:1,buy_qty:1,item_name_buy:'',get_qty:1,item_name:'',image:'',buy_description:'',discount_percentage:'',minimum_purchase_amount:'',applicable_item_if_any:'',discount_description:'',buy_item_name:'',buy_qty_need:1,get_item_name:'',loyalty_description:'',promotion_name:'',your_coupon:'',select_option:'Send it to my customers',target_customers:'All',days_type:'search_customer',past_days:'',amount:'',days:'',base_auto_send_the_coupons:1,subscription_period:1,new_auto_send_the_coupons:1,new_cus_auto_send_the_coupons:1,product_name_buy:'',product_name:''});

const [loading,setLoading] = useState(false);

const [coupon, setCoupon] = useState([]);
const [product, setProduct] = useState([]);


const [show, setShow] = useState(false);
const handleClose = () => setShow(false);

const [mindate,setMinDate] = useState();
const [mindateto,setMinDateTo] = useState();
//const handleShow = () => setShow(true);

useEffect(() => {
    document.title = ` Coupon | Blue Faucet`;
    document.getElementById('bodywrap').className='copnpgmain grenicn';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });  

const min_date  = moment().format('YYYY-MM-DD');
setMinDate(min_date);
setMinDateTo(min_date);
	/* Start get coupons data */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('coupons', formData)
			.then(response => {
			setLoading(false);	
			if(response.data.status === 0){
				
			}else{
			   setCoupon(response.data.data);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get coupons data */
	 
	 /* Start get coupons data */
	let formData1 = new FormData(); 
		formData1.append('token', localStorage.getItem("token"));
		API.post('get_product_or_service_for_coupon', formData1)
			.then(response => {
			setLoading(false);	
			if(response.data.status === 0){
				
			}else{
			   setProduct(response.data.data);
			}                  
		}).catch(error => {
			 
		});  
	 /* End get coupons data */
	
},[]) 
/* Start Step Show */
const handleShowTab1 = () => {
	const validationErrors = validatecouponstep1(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
	setErrors({});
	setErrors(validationErrors);
	if(noErrors){
		setShowtab1(false);
		setShowtab2(true);
	}
}
const handleShowTab2 = () => {
	const validationErrors = validatecouponstep2(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
	setErrors({});
	setErrors(validationErrors);
	if(noErrors){
		setShowtab2(false);
		setShowtab3(true);
	}
}
const handleShowTab3 = () => {
	const validationErrors = validatecouponstep3(inputs,selected);
    const noErrors = Object.keys(validationErrors).length === 0;
	setErrors({});
	setErrors(validationErrors);
	if(noErrors){
		setShowtab3(false);
		setShowtab4(true);
	}
}
const handleShowTab4 = () => {
	setLoading(true);
	
	let coupon_name=inputs.coupon_name;
	let date_type=inputs.date_type;
	
	let start_date='';
	let end_date='';
	let return_before='';
	if(inputs.date_type==='Start & End')
	{
		start_date=inputs.start_date;
		end_date=inputs.end_date;
	}else{
		return_before=inputs.return_before;
	}
	let coupon_type=inputs.coupon_type;
	let one_time_use_only=inputs.one_time_use_only;
	let double_coupon_is_not_allowed=inputs.double_coupon_is_not_allowed;
	
	let buy_qty='';
	let item_name_buy='';
	let get_qty='';
	let item_name='';
	let description='';
	
	let discount_percentage='';
	let minimum_purchase_amount='';
	let applicable_item_if_any='';
	
	let buy_item_name='';
	let buy_qty_need='';
	let get_item_name='';
	
	let image='';
	if(inputs.coupon_type==='Buy & Get')
	{
		buy_qty=inputs.buy_qty;
		item_name_buy=inputs.item_name_buy;
		get_qty=inputs.get_qty;
		item_name=inputs.item_name;
		description=inputs.buy_description;
		image=inputs.image;
	}else if(inputs.coupon_type==='Discount Offer')
	{
		discount_percentage=inputs.discount_percentage;
		minimum_purchase_amount=inputs.minimum_purchase_amount;
		applicable_item_if_any=inputs.applicable_item_if_any;
		description=inputs.discount_description;
		image=inputs.image;
	}else if(inputs.coupon_type==='Loyalty Setup')
	{
		buy_item_name=inputs.buy_item_name;
		buy_qty_need=inputs.buy_qty_need;
		get_item_name=inputs.get_item_name;
		description=inputs.loyalty_description;
		image=inputs.image;
	}else if(inputs.coupon_type==='Invitation')
	{
		//buy_qty=inputs.buy_qty;
		//get_qty=inputs.get_qty;
		item_name=inputs.item_name;
		description=inputs.buy_description;
		image=inputs.image;
	}
	
	let promotion_name=coupon_name;
	let select_option=inputs.select_option;
	let target_customers=inputs.target_customers;
	
	
	let days_type='';
	let past_days='';
	let amount='';
	let days='';
	let auto_send_coupon=0;
	let zip_codes=[];
	if(target_customers==='Based on Criteria')
	{
		days_type=inputs.days_type;
		if(days_type==='search_customer')
		{
			past_days=inputs.past_days;
			amount=inputs.amount;
			
		}else if(days_type==='not_returned')
		{
			days=inputs.days;
		}
		if(inputs.coupon_type==='Buy & Get')
		{
			auto_send_coupon=inputs.base_auto_send_the_coupons;
		}else if(inputs.coupon_type==='Discount Offer')
		{
			auto_send_coupon=inputs.base_auto_send_the_coupons;
		}
	}else if(target_customers==='Selected Customer')
	{
		zip_codes=selected;
		//auto_send_coupon=inputs.new_auto_send_the_coupons;
	}else if(target_customers==='All New Customer')
	{
		//auto_send_coupon=inputs.new_cus_auto_send_the_coupons;
	}
	let formData = new FormData();
    formData.append('token', localStorage.getItem("token"));	
	formData.append('coupon_name', coupon_name);
	formData.append('date_type', date_type);
	formData.append('start_date', start_date);
	formData.append('end_date', end_date);
	formData.append('return_befor', return_before);
	formData.append('coupon_type', coupon_type);
	formData.append('one_time_used',one_time_use_only);
	formData.append('double_coupon_not_allowed', double_coupon_is_not_allowed);
	formData.append('image', image);
	formData.append('description', description);
	formData.append('buy_quantity', buy_qty);
	formData.append('item_name_buy', item_name_buy);
	formData.append('get_quantity', get_qty);
	formData.append('item_name', item_name);
	
	formData.append('discount_percentage', discount_percentage);
	formData.append('minimum_purchase_amount', minimum_purchase_amount);
	formData.append('applicable_item_if_any', applicable_item_if_any);
	
	formData.append('buy_item_name', buy_item_name);
	formData.append('buy_quantity_needed', buy_qty_need);
	formData.append('get_item_name', get_item_name);
	
	formData.append('promotion_name', promotion_name);
	formData.append('select_option', select_option);
	formData.append('target_customers', target_customers);
	formData.append('days_type', days_type);
	formData.append('past_days', past_days);
	formData.append('amount', amount);
	formData.append('days', days);
	formData.append('auto_send_coupon', auto_send_coupon);
	formData.append('subscription_period', '');
	formData.append('zip_codes', zip_codes);
	API.post('create_coupon', formData)
		.then(response => {
			setLoading(false); 
		if(response.data.status === 0){
			//alert(response.data.message);
			gettoastwarning(response.data.message,'');
			setLoading(false); 
		}else{
		   //alert(response.data.message);
		  //window.location.href = "/mer-sending-coupons";
		  gettoastsuccess(response.data.message,'/mer-sending-coupons');
		}                  
	}).catch(error => {
		 setLoading(false);
		  //alert("Something went wrong. Please try again later.");
		  gettoastwarning("Something went wrong. Please try again later.",'');
	});  
}
/* End Step Show */
 /* Start Value set */
const handleOnChangeDate = (event) => {
      event.persist();
	  setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));  
      if(event.target.value==='Start & End')
      {
			setShowdate(true);
      }else{
			setShowdate(false);
      }		  
}
const handleOnChangeDays = (event) => {
      event.persist();
	  setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));  
        
}
const handleOnChange = (event) => {
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value})); 
		if(event.target.value==='Loyalty Setup')
		{
			var lbl1='one_time_use_only';
		    setInputs(inputs => ({...inputs, [lbl1]: 0}));
		}else{
			var lbl2='one_time_use_only';
		    setInputs(inputs => ({...inputs, [lbl2]: 1}));
		}
		
}
const handleOnChangePer = (event) => {
      event.persist();
	  if(event.target.value==='')
	  {
		  setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
	  }else{
		  const enteredPrice = event.target.value;
		   const priceRegex = /^\$?\d+(\.\d{1,2})?$/;

		// Check if the entered price matches the expected format
		const isValid = priceRegex.test(enteredPrice);
		if(isValid){
		  setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));  
		}
	  }		
}

const handleOnChangeProdBuy = (event) => {
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));  
	const selectedText = event.target.options[event.target.selectedIndex].text;
	var product_name_lbl='product_name_buy';
      setInputs(inputs => ({...inputs, [product_name_lbl]: selectedText}));
	
	  
}
const handleOnChangeProd = (event) => {
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));  
	const selectedText = event.target.options[event.target.selectedIndex].text;
	var product_name_lbl='product_name';
      setInputs(inputs => ({...inputs, [product_name_lbl]: selectedText}));
	
	  
}

const handleNumberChange = (event) => {
      event.persist();
	  const inputNumber = event.target.value.replace(/\D/g, '');
      setInputs(inputs => ({...inputs, [event.target.name]: inputNumber}));     
}
 

const handleOnChangeminDate = (event) => {
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));  
		const min_date  = moment(event.target.value).format('YYYY-MM-DD');
		setMinDateTo(min_date);
}


const handleOnChangeDes = (event) => {
      event.persist();
	  
	  const inputText = event.target.value;
    const words = inputText.split(/\s+/);
    if (words.length <= 30) {
	  setInputs(inputs => ({...inputs, [event.target.name]: inputText}));
    }	  
}
const handleOnChange1 = (event) => {
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value})); 
      setShow(true);
      
}
const handleCheckboxChangeOneTime = (event) => {
	var lbl='one_time_use_only';
	if(event.target.checked)
	{
		setInputs(inputs => ({...inputs, [lbl]: 1}));
	}else{
		setInputs(inputs => ({...inputs, [lbl]: 0}));
	}
};
const handleCheckboxChangeDouble = (event) => {
	var lbl='double_coupon_is_not_allowed';
	if(event.target.checked)
	{
		setInputs(inputs => ({...inputs, [lbl]: 1}));
	}else{
		setInputs(inputs => ({...inputs, [lbl]: 0}));
	}
};

const handleCheckboxChangeAutoSend = (event) => {
	var lbl='base_auto_send_the_coupons';
	if(event.target.checked)
	{
		setInputs(inputs => ({...inputs, [lbl]: 1}));
	}else{
		setInputs(inputs => ({...inputs, [lbl]: 0}));
	}
};
/*const handleCheckboxChangeNew = (event) => {
	var lbl='new_auto_send_the_coupons';
	if(event.target.checked)
	{
		setInputs(inputs => ({...inputs, [lbl]: 1}));
	}else{
		setInputs(inputs => ({...inputs, [lbl]: 0}));
	}
};
const handleCheckboxChangeNewCus = (event) => {
	var lbl='new_cus_auto_send_the_coupons';
	if(event.target.checked)
	{
		setInputs(inputs => ({...inputs, [lbl]: 1}));
	}else{
		setInputs(inputs => ({...inputs, [lbl]: 0}));
	}
};*/


/* End Value set */
function BuyQtyIncCount() 
{
	var count=parseFloat(inputs.buy_qty);
	count = count + 1;
	var buy_qty_lbl='buy_qty';
	setInputs(inputs => ({...inputs, [buy_qty_lbl]: count}));
}
function BuyQtyDecCount() 
{
	var count=parseFloat(inputs.buy_qty);
	count = count - 1;
	if(count >0)
	{
		var buy_qty_lbl='buy_qty';
		setInputs(inputs => ({...inputs, [buy_qty_lbl]: count}));
	}
}
function GetQtyIncCount() 
{
	var count=parseFloat(inputs.get_qty);
	count = count + 1;
	var get_qty_lbl='get_qty';
	setInputs(inputs => ({...inputs, [get_qty_lbl]: count}));
}
function GetQtyDecCount() 
{
	var count=parseFloat(inputs.get_qty);
	count = count - 1;
	if(count >0)
	{
		var get_qty_lbl='get_qty';
		setInputs(inputs => ({...inputs, [get_qty_lbl]: count}));
	}
}
function BuyQtyNeedIncCount() 
{
	var count=parseFloat(inputs.buy_qty_need);
	count = count + 1;
	var buy_qty_need_lbl='buy_qty_need';
	setInputs(inputs => ({...inputs, [buy_qty_need_lbl]: count}));
}
function BuyQtyNeedDecCount() 
{
	var count=parseFloat(inputs.buy_qty_need);
	count = count - 1;
	if(count >0)
	{
		var buy_qty_need_lbl='buy_qty_need';
		setInputs(inputs => ({...inputs, [buy_qty_need_lbl]: count}));
	}
}

const handleOnChangeCoupon = (event) => {
      event.persist();
      var coupon_id=event.target.value;
	  let formData = new FormData(); 
		formData.append('coupon_id', coupon_id);
		API.post('get_coupon_detail', formData)
			.then(response => {
			setLoading(false);	
			if(response.data.status === 0){
				
			}else{
				var coupon_name=response.data.data.coupon_name;
				var date_type=response.data.data.date_type;
				var start_date='';
				var end_date='';
				var return_before='';
				if(response.data.data.date_type==='Start & End')
				{
					start_date=response.data.data.start_date;
					end_date=response.data.data.end_date;
					setShowdate(true);
				}else{
					return_before=response.data.data.return_befor;
					setShowdate(false);
				}
				var coupon_type=response.data.data.coupon_type;
				var one_time_use_only=response.data.data.one_time_used;
				var double_coupon_is_not_allowed=response.data.data.double_coupon_not_allowed;
				
				
				var buy_qty=1;
				var item_name_buy='';
				var get_qty=1;
				var item_name='';
				var buy_description='';
				var product_name_buy='';
				var product_name='';
				
				var discount_percentage='';
				var minimum_purchase_amount='';
				var applicable_item_if_any='';
				var discount_description='';
				
				var buy_item_name='';
				var buy_qty_need=1;
				var get_item_name='';
				var loyalty_description='';
				
				var image='';
				if(coupon_type==='Buy & Get')
				{
					buy_qty=response.data.data.buy_quantity;
					item_name_buy=response.data.data.item_name_buy_id;
					get_qty=response.data.data.get_quantity;
					item_name=response.data.data.item_name;
					buy_description=response.data.data.description;
					image=response.data.data.image;
					product_name_buy=response.data.data.item_name_buy;
					product_name=response.data.data.product_item_name;
				}else if(coupon_type==='Discount Offer')
				{
					discount_percentage=response.data.data.discount_percentage;
					minimum_purchase_amount=response.data.data.minimum_purchase_amount;
					applicable_item_if_any=response.data.data.applicable_item_if_any;
					discount_description=response.data.data.description;
					image=response.data.data.image;
				}else if(coupon_type==='Loyalty Setup')
				{
					buy_item_name=response.data.data.buy_item_name;
					buy_qty_need=response.data.data.buy_quantity_needed;
					get_item_name=response.data.data.get_item_name;
					loyalty_description=response.data.data.description;
					image=response.data.data.image;
				}else if(coupon_type==='Invitation')
				{
					//buy_qty=response.data.data.buy_quantity;
					//get_qty=response.data.data.get_quantity;
					item_name=response.data.data.item_name;
					buy_description=response.data.data.description;
					image=response.data.data.image;
				}
				
				var promotion_name=response.data.data.promotion_name;
				var select_option=response.data.data.select_option;
				var target_customers=response.data.data.target_customers;
				
				
				var days_type='search_customer';
				var past_days='';
				var amount='';
				var days='';
				var base_auto_send_the_coupons=1;
				var new_auto_send_the_coupons=1;
				var zip_codes=[];
				
				if(target_customers==='Based on Criteria')
				{
					days_type=response.data.data.days_type;					
					if(days_type==='search_customer')
					{
						past_days=response.data.data.visit_days;
						amount=response.data.data.spent_over_amount;
						
					}else if(days_type==='not_returned')
					{
						days=response.data.data.in_the_past_days;
						
					}
					
					
					base_auto_send_the_coupons=response.data.data.auto_send_coupon;
				}else if(target_customers==='Selected Customer')
				{
					zip_codes=response.data.data.zip_codes;
					setSelected(zip_codes);
					new_auto_send_the_coupons=response.data.data.auto_send_coupon;
				}
				
			   setInputs({
				   coupon_name:coupon_name,
				   date_type:date_type,
				   start_date:start_date,
				   end_date:end_date,
				   return_before:return_before,
				   coupon_type:coupon_type,
				   one_time_use_only:one_time_use_only,
				   double_coupon_is_not_allowed:double_coupon_is_not_allowed,
				   buy_qty:buy_qty,
				   item_name_buy:item_name_buy,
				   get_qty:get_qty,
				   item_name:item_name,
				   image:image,
				   buy_description:buy_description,
				   discount_percentage:discount_percentage,
				   minimum_purchase_amount:minimum_purchase_amount,
				   applicable_item_if_any:applicable_item_if_any,
				   discount_description:discount_description,
				   buy_item_name:buy_item_name,
				   buy_qty_need:buy_qty_need,
				   get_item_name:get_item_name,
				   loyalty_description:loyalty_description,
				   promotion_name:promotion_name,
				   your_coupon:'',
				   select_option:select_option,
				   target_customers:target_customers,
				   days_type:days_type,
				   past_days:past_days,
				   amount:amount,
				   days:days,
				   base_auto_send_the_coupons:base_auto_send_the_coupons,
				   subscription_period:1,
				   new_auto_send_the_coupons:new_auto_send_the_coupons,
				   product_name_buy:product_name_buy,
				   product_name:product_name
				   }
			   );
			}                  
		}).catch(error => {
			 
		});  
}


const handleCancel = () => {
	//window.location.reload();
	window.location.href = "/mer-sending-coupons";
}

const getCoupontype = (val,discount_percentage,minimum_purchase_amount,product_item_name_buy,product_item_name,buy_qty,get_qty) => {
	var type='';
	if(val==='Buy & Get')
	{
		type='Buy  '+buy_qty+' '+product_item_name_buy+' and Get '+get_qty+' '+product_item_name+' free of Charge';
	}else if(val==='Discount Offer'){
		type=discount_percentage+'% discount when purchase over $'+minimum_purchase_amount;
	}else{
		type=val;
	}
	return type;
}
const getCoupondes = (val) => {
	var des='';
	if(val==='Buy & Get')
	{
		des=inputs.buy_description;
	}else if(val==='Discount Offer'){
		des=inputs.discount_description;
	}else if(val==='Loyalty Setup'){
		des=inputs.loyalty_description;
	}else if(val==='Invitation'){
		des=inputs.buy_description;
	}
	return des;
}

    return (
        <>
         <section className="gen-wrap">
		 <ToastContainer />
            <Row className="maincntmrcnt">
               <Col xs={2} md={2} className="align-self-center px-0">
               <button onClick={() =>
                  navigate(-1)} className="back-btn d-none">
                  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
                  </svg>
               </button>
               </Col>
               <Col xs={8} md={8} className="align-self-center text-center">
               <h3>Create Coupon</h3>
               </Col>
               <Col xs={2} md={2} className="align-self-center text-end mcustmrsw slctbtnsw">
               <Button id="btn2" className="" href={void(0)} variant=""><img src="assets/images/merchant/svg/plus-icn.svg" className="img-fluid" alt="Logo"/></Button>{' '}
               </Col>
            </Row>
            <Container>
               <Row className="main-contpg">
                  <Col md={12} xs={12} className="gttop stepr-line">
                  <div className={showtab1 ? 'glfs stpline' : 'glfs'}><span class="step-circle">1</span></div>
                  <div className={showtab2 ? 'glfs stpline' : 'glfs'}><span class="step-circle">2</span></div>
                  <div className={showtab3 ? 'glfs stpline' : 'glfs'}><span class="step-circle">3</span></div>
                  <div className={showtab4 ? 'glfs stpline' : 'glfs'}><span class="step-circle">4</span></div>
                  </Col>
                  <Col md={12} xs={12} className="gtform steper-body">
                  <form>
				  {showtab1 && (
                     <Row className="step1">
                        <Col md={12} xs={12}>
                        <h3>Enter New Coupon Name</h3>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Coupon Name</Form.Label>
                           <Form.Control type="text" placeholder="E.g., Special October Offer" value={inputs.coupon_name} name="coupon_name" onChange={handleOnChange} />
							{errors.coupon_name && (<p className="text-danger">{errors.coupon_name}</p>)}
						</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Select From Your Coupon</Form.Label>
                           <Form.Select aria-label="Default select example" name="coupon_id" onChange={handleOnChangeCoupon}>
                              <option value="">Select from Existing Coupon</option>
							  {coupon && coupon.map((val,index) => {
								  if(val.coupon_type!=='Welcome coupon')
								  {
								return(
								  
									<option value={val.id}>{val.coupon_name}</option>
								)
								  }else{
									  return null;
								  }
							  })} 
                           </Form.Select>
						   {errors.your_coupon && (<p className="text-danger">{errors.your_coupon}</p>)}
                        </Form.Group>
                        <Row className="align-items-center">
						  <label>Your Coupon Effective Period ?</label>
                           <Col md xs className="cusradio">
                           <div class="py-2">
                              <input class="radio-label" type="radio" name="date_type" id="yes" value="Start & End" checked={inputs.date_type === "Start & End"} onChange={handleOnChangeDate}/>
                              <label class="button-label" for="yes">Start & End</label>
                           </div>
                           </Col>
						   <Col md='auto' xs='auto'>
							<span>Or</span>
						   </Col>
                           <Col md xs className="cusradio">
                           <div class="py-2">
                              <input class="radio-label" type="radio" name="date_type" id="no" value="Return Before" checked={inputs.date_type === "Return Before"} onChange={handleOnChangeDate} />
                              <label class="button-label" for="no">Return Before</label>
                           </div>
                           </Col>
						   {showdate ? (
                           <Col md={12} xs={12} className="mb-3 mt-3">
						   
							   <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
								  <Form.Label>Start Date</Form.Label>
								  <Form.Control type="date" name="start_date" value={inputs.start_date} onChange={handleOnChangeminDate} min={mindate}  />
								  {errors.start_date && (<p className="text-danger">{errors.start_date}</p>)}
							   </Form.Group>
						   
							   <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
								  <Form.Label>End Date</Form.Label>
								  <Form.Control type="date" name="end_date" value={inputs.end_date} onChange={handleOnChange} min={mindateto}  />
								  {errors.end_date && (<p className="text-danger">{errors.end_date}</p>)}
							   </Form.Group>
						   
                           </Col>
						   ) : (
								<Col md={12} xs={12} className="mb-3 mt-3">
								   <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
									  <Form.Label>Return Before </Form.Label>
									  <Form.Control type="date" name="return_before" value={inputs.return_before} onChange={handleOnChange} min={mindate}  />
									  {errors.return_before && (<p className="text-danger">{errors.return_before}</p>)}
								   </Form.Group>
								</Col>
						   )}
                        </Row>
                        <h3>Select Coupon Type</h3>
                        <Row className="mt-2">
                          <Col md={12} xs={12} className="cusradio">
                           <div class="py-1">
                              <input class="radio-label" type="radio" name="coupon_type" id="sct1" value="Buy & Get" checked={inputs.coupon_type === "Buy & Get"} onChange={handleOnChange} />
                              <label class="button-label" for="sct1">
                                 <svg className="me-2" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M22.0001 8.5C22.0001 11.76 19.6001 14.45 16.4801 14.92V14.86C16.1701 10.98 13.0201 7.83 9.11008 7.52H9.08008C9.55008 4.4 12.2401 2 15.5001 2C19.0901 2 22.0001 4.91 22.0001 8.5Z" />
                                    <path d="M14.98 14.98C14.73 11.81 12.19 9.27 9.02 9.02C8.85 9.01 8.67 9 8.5 9C4.91 9 2 11.91 2 15.5C2 19.09 4.91 22 8.5 22C12.09 22 15 19.09 15 15.5C15 15.33 14.99 15.15 14.98 14.98ZM9.38 16.38L8.5 18L7.62 16.38L6 15.5L7.62 14.62L8.5 13L9.38 14.62L11 15.5L9.38 16.38Z" />
                                 </svg>
                                 Buy & Get
                              </label>
                           </div>
                           </Col>
                           <Col md={12} xs={12} className="cusradio">
                           <div class="py-1">
                              <input class="radio-label" type="radio" name="coupon_type" id="sct2" value="Discount Offer" checked={inputs.coupon_type === "Discount Offer"} onChange={handleOnChange}/>
                              <label class="button-label" for="sct2">
                                 <svg className="me-2" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M21.3 10.8401C21.69 10.8401 22 10.5301 22 10.1401V9.21011C22 5.11011 20.75 3.86011 16.65 3.86011H7.35C3.25 3.86011 2 5.11011 2 9.21011V9.68011C2 10.0701 2.31 10.3801 2.7 10.3801C3.6 10.3801 4.33 11.1101 4.33 12.0101C4.33 12.9101 3.6 13.6301 2.7 13.6301C2.31 13.6301 2 13.9401 2 14.3301V14.8001C2 18.9001 3.25 20.1501 7.35 20.1501H16.65C20.75 20.1501 22 18.9001 22 14.8001C22 14.4101 21.69 14.1001 21.3 14.1001C20.4 14.1001 19.67 13.3701 19.67 12.4701C19.67 11.5701 20.4 10.8401 21.3 10.8401ZM9 8.88011C9.55 8.88011 10 9.33011 10 9.88011C10 10.4301 9.56 10.8801 9 10.8801C8.45 10.8801 8 10.4301 8 9.88011C8 9.33011 8.44 8.88011 9 8.88011ZM15 15.8801C14.44 15.8801 13.99 15.4301 13.99 14.8801C13.99 14.3301 14.44 13.8801 14.99 13.8801C15.54 13.8801 15.99 14.3301 15.99 14.8801C15.99 15.4301 15.56 15.8801 15 15.8801ZM15.9 9.48011L9.17 16.2101C9.02 16.3601 8.83 16.4301 8.64 16.4301C8.45 16.4301 8.26 16.3601 8.11 16.2101C7.82 15.9201 7.82 15.4401 8.11 15.1501L14.84 8.42011C15.13 8.13011 15.61 8.13011 15.9 8.42011C16.19 8.71011 16.19 9.19011 15.9 9.48011Z"/>
                                 </svg>
                                 Discount Offer
                              </label>
                           </div>
                           </Col>
                           {/*<Col md={12} xs={12} className="cusradio">
                           <div class="py-1">
                              <input class="radio-label" type="radio" name="coupon_type" id="sct3" value="Loyalty Setup" checked={inputs.coupon_type === "Loyalty Setup"} onChange={handleOnChange}/>
                              <label class="button-label" for="sct3">
                                 <svg className="me-2" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M17 22H7C6.59 22 6.25 21.66 6.25 21.25C6.25 20.84 6.59 20.5 7 20.5H17C17.41 20.5 17.75 20.84 17.75 21.25C17.75 21.66 17.41 22 17 22Z" />
                                    <path d="M20.35 5.52004L16.35 8.38004C15.82 8.76004 15.06 8.53004 14.83 7.92004L12.94 2.88004C12.62 2.01004 11.39 2.01004 11.07 2.88004L9.16998 7.91004C8.93998 8.53004 8.18997 8.76004 7.65998 8.37004L3.65998 5.51004C2.85998 4.95004 1.79998 5.74004 2.12998 6.67004L6.28998 18.32C6.42998 18.72 6.80998 18.98 7.22998 18.98H16.76C17.18 18.98 17.56 18.71 17.7 18.32L21.86 6.67004C22.2 5.74004 21.14 4.95004 20.35 5.52004ZM14.5 14.75H9.49998C9.08998 14.75 8.74998 14.41 8.74998 14C8.74998 13.59 9.08998 13.25 9.49998 13.25H14.5C14.91 13.25 15.25 13.59 15.25 14C15.25 14.41 14.91 14.75 14.5 14.75Z" />
                                 </svg>
                                 Loyalty Program
                              </label>
                           </div>
                           </Col>*/}
                           <Col md={12} xs={12} className="cusradio">
                           <div class="py-1">
                              <input class="radio-label" type="radio" name="coupon_type" id="sct4" value="Invitation" checked={inputs.coupon_type === "Invitation"} onChange={handleOnChange}/>
                              <label class="button-label" for="sct4">
                                 <svg className="me-2" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M22.0001 8.5C22.0001 11.76 19.6001 14.45 16.4801 14.92V14.86C16.1701 10.98 13.0201 7.83 9.11008 7.52H9.08008C9.55008 4.4 12.2401 2 15.5001 2C19.0901 2 22.0001 4.91 22.0001 8.5Z" />
                                    <path d="M14.98 14.98C14.73 11.81 12.19 9.27 9.02 9.02C8.85 9.01 8.67 9 8.5 9C4.91 9 2 11.91 2 15.5C2 19.09 4.91 22 8.5 22C12.09 22 15 19.09 15 15.5C15 15.33 14.99 15.15 14.98 14.98ZM9.38 16.38L8.5 18L7.62 16.38L6 15.5L7.62 14.62L8.5 13L9.38 14.62L11 15.5L9.38 16.38Z" />
                                 </svg>
                                 Invitation
                              </label>
                           </div>
                           </Col>
                        </Row>
                        <Col md={12} xs={12}>
                        <Form.Group className="mb-3 mt-3 ckbxgrn" controlId="">
                           <div key="default-1" className="">
                              <Form.Check type="checkbox" id="default-1"  label="One-Time use only" name="one_time_use_only" value="One Time use only" checked={inputs.one_time_use_only === 1} onChange={handleCheckboxChangeOneTime} />
                           </div>
                        </Form.Group>
                        </Col>
                        <Col md={12} xs={12}>
                        <Form.Group className="mb-3 ckbxgrn" controlId="">
                           <div key="default-2" className="">
                              <Form.Check type="checkbox" id="default-2" label="Double Coupon is not allowed" name="double_coupon_is_not_allowed" checked={inputs.double_coupon_is_not_allowed === 1} onChange={handleCheckboxChangeDouble}/>
                           </div>
                        </Form.Group>
                        </Col>
                        </Col>            
                        <Col md={12} xs={12}>
                        <Row class="btbox">
                           <Col md={6} xs={6} className="">
						   <NavLink to="#" className="step2bck btn-border" onClick={handleCancel}> Cancel </NavLink>
						   </Col>
                           <Col md={6} xs={6} className="">
						   <NavLink to="#" className="step1nxt btn-fill" onClick={handleShowTab1}> Continue </NavLink>
						   </Col> 
                        </Row>
                        </Col>                
                     </Row>
				  ) }
				  
				  {showtab2 && (
                     <Row className="step2">
                        <Col md={12} xs={12}>
					{inputs.coupon_type==='Buy & Get' && (
						<Col md={12} xs={12} className="">
                        <h3>Setup Buy & Get</h3>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Buy Quantity</Form.Label>
                           <div className="btnqnty">
                              <input type="text" name="buy_qty" value={inputs.buy_qty} className="input-qty input-rounded"/>
                              <button onClick={BuyQtyIncCount} className="qty-btn-plus btn-primary btn-rounded mr-1" type="button"><i className="fa fa-plus"></i></button>
                              <button onClick={BuyQtyDecCount} className="qty-btn-minus btn-primary btn-rounded ml-1" type="button"><i className="fa fa-minus"></i></button>
                           </div>
						   {errors.buy_qty && (<p className="text-danger">{errors.buy_qty}</p>)}
                        </Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Item Name</Form.Label>
							<Form.Select aria-label="Default select example" name="item_name_buy" value={inputs.item_name_buy} onChange={handleOnChangeProdBuy}>
                              <option value="">Select Product or Service</option>
							  {product && product.map((val,index) => {
								return(
									<option value={val.id}>{val.name}</option>
								)
							  })} 
                           </Form.Select>
							{errors.item_name_buy && (<p className="text-danger">{errors.item_name_buy}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Get Quantity</Form.Label>
                           <div className="btnqnty">
                              <input type="text" name="get_qty" value={inputs.get_qty} className="input-qty input-rounded"/>
                              <button onClick={GetQtyIncCount} className="qty-btn-plus btn-primary btn-rounded mr-1" type="button"><i className="fa fa-plus"></i></button>
                              <button onClick={GetQtyDecCount} className="qty-btn-minus btn-primary btn-rounded ml-1" type="button"><i className="fa fa-minus"></i></button>
                           </div>
						   {errors.get_qty && (<p className="text-danger">{errors.get_qty}</p>)}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Item Name</Form.Label>
							<Form.Select aria-label="Default select example" name="item_name" value={inputs.item_name} onChange={handleOnChangeProd}>
                              <option value="">Select Product or Service</option>
							  {product && product.map((val,index) => {
								return(
									<option value={val.id}>{val.name}</option>
								)
							  })} 
                           </Form.Select>
							{errors.item_name && (<p className="text-danger">{errors.item_name}</p>)}
						</Form.Group>
						
                        <Col md={12} xm={12} className="selctimg-mrktng cou-img-slide">
                            <div className="cusradio hmtp-scrl">
							<ul className="cou-img-slide">
							{/*<li>
                             <input className="radio-label hmtp-scldv" type="radio" name="image" id="one" value="1" onChange={handleOnChange} checked={inputs.image === "1"} /><label className="button-label" htmlFor="one"><img src= {'assets/images/coupan.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
                             </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="two" value="2" onChange={handleOnChange} checked={inputs.image === "2"}/><label className="button-label" htmlFor="two"><img src= {'assets/images/coupan-2.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							</li>*/}
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="three" value="3" onChange={handleOnChange1} checked={inputs.image === "3"}/><label className="button-label" htmlFor="three"><img src= {'assets/images/coupan-3.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
                             </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="four" value="4" onChange={handleOnChange1} checked={inputs.image === "4"}/><label className="button-label" htmlFor="four"><img src= {'assets/images/coupan-4.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="five" value="5" onChange={handleOnChange1} checked={inputs.image === "5"}/><label className="button-label" htmlFor="five"><img src= {'assets/images/coupan-5.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="six" value="6" onChange={handleOnChange1} checked={inputs.image === "6"}/><label className="button-label" htmlFor="six"><img src= {'assets/images/coupan-6.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 {/*<li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="seven" value="7" onChange={handleOnChange1} checked={inputs.image === "7"}/><label className="button-label" htmlFor="seven"><img src= {'assets/images/coupan-7.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>*/}
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="eight" value="8" onChange={handleOnChange1} checked={inputs.image === "8"}/><label className="button-label" htmlFor="eight"><img src= {'assets/images/coupan-8.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="nine" value="9" onChange={handleOnChange1} checked={inputs.image === "9"}/><label className="button-label" htmlFor="nine"><img src= {'assets/images/coupan-9.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="ten" value="10" onChange={handleOnChange1} checked={inputs.image === "10"}/><label className="button-label" htmlFor="ten"><img src= {'assets/images/coupan-10.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="eleven" value="11" onChange={handleOnChange1} checked={inputs.image === "11"}/><label className="button-label" htmlFor="eleven"><img src= {'assets/images/coupan-11.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							</ul>
							</div>
                           </Col>
						   {errors.image && (<p className="text-danger">{errors.image}</p>)}
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Description of your offer (max 30 words)</Form.Label>
                           <Form.Control as="textarea" rows={3} placeholder="Start here..." name="buy_description" value={inputs.buy_description} onChange={handleOnChangeDes} />
							{errors.buy_description && (<p className="text-danger">{errors.buy_description}</p>)}
						</Form.Group>
                        <Form.Label className="lsttxt-instp">It will be displayed on your coupon</Form.Label>
                        </Col>
					)}
					  {inputs.coupon_type==='Discount Offer' && (
                        <Col md={12} xs={12} className="">
                        <h3>Setup Discount Offer</h3>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Discount Percentage (%)</Form.Label>
                           <Form.Control type="Number" placeholder="E.g., 10%" name="discount_percentage" value={inputs.discount_percentage} onChange={handleOnChangePer}  />
							{errors.discount_percentage && (<p className="text-danger">{errors.discount_percentage}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Minimum Purchase Amount ($)</Form.Label>
                           <Form.Control type="Number" placeholder="E.g., $100" name="minimum_purchase_amount" value={inputs.minimum_purchase_amount} onChange={handleOnChangePer}  />
							{errors.minimum_purchase_amount && (<p className="text-danger">{errors.minimum_purchase_amount}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Applicable Item If Any</Form.Label>
                           <Form.Select aria-label="Default select example" name="applicable_item_if_any" value={inputs.applicable_item_if_any} onChange={handleOnChange}>
                              <option value="">Select Product or Service</option>
							  <option value="Any Product/services">Any Product/services</option>
							  {product && product.map((val,index) => {
								return(
									<option value={val.id}>{val.name}</option>
								)
							  })} 
                           </Form.Select>
						   {errors.applicable_item_if_any && (<p className="text-danger">{errors.applicable_item_if_any}</p>)}
						</Form.Group>
                        <Col md={12} xm={12} className="selctimg-mrktng cou-img-slide">
                            <div className="cusradio hmtp-scrl">
							<ul className="cou-img-slide">
							{/*<li>
                             <input className="radio-label hmtp-scldv" type="radio" name="image" id="one" value="1" onChange={handleOnChange} checked={inputs.image === "1"} /><label className="button-label" htmlFor="one"><img src= {'assets/images/coupan.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
                             </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="two" value="2" onChange={handleOnChange} checked={inputs.image === "2"}/><label className="button-label" htmlFor="two"><img src= {'assets/images/coupan-2.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							</li>*/}
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="three" value="3" onChange={handleOnChange1} checked={inputs.image === "3"}/><label className="button-label" htmlFor="three"><img src= {'assets/images/coupan-3.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
                             </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="four" value="4" onChange={handleOnChange1} checked={inputs.image === "4"}/><label className="button-label" htmlFor="four"><img src= {'assets/images/coupan-4.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="five" value="5" onChange={handleOnChange1} checked={inputs.image === "5"}/><label className="button-label" htmlFor="five"><img src= {'assets/images/coupan-5.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="six" value="6" onChange={handleOnChange1} checked={inputs.image === "6"}/><label className="button-label" htmlFor="six"><img src= {'assets/images/coupan-6.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="seven" value="7" onChange={handleOnChange1} checked={inputs.image === "7"}/><label className="button-label" htmlFor="seven"><img src= {'assets/images/coupan-7.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="eight" value="8" onChange={handleOnChange1} checked={inputs.image === "8"}/><label className="button-label" htmlFor="eight"><img src= {'assets/images/coupan-8.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="nine" value="9" onChange={handleOnChange1} checked={inputs.image === "9"}/><label className="button-label" htmlFor="nine"><img src= {'assets/images/coupan-9.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="ten" value="10" onChange={handleOnChange1} checked={inputs.image === "10"}/><label className="button-label" htmlFor="ten"><img src= {'assets/images/coupan-10.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="eleven" value="11" onChange={handleOnChange1} checked={inputs.image === "11"}/><label className="button-label" htmlFor="eleven"><img src= {'assets/images/coupan-11.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							</ul>
							</div>
                           </Col>
						   {errors.image && (<p className="text-danger">{errors.image}</p>)}
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Description of your offer (max 30 words)</Form.Label>
                           <Form.Control as="textarea" rows={3} placeholder="Start here..." name="discount_description" value={inputs.discount_description} onChange={handleOnChangeDes} />
                           {errors.discount_description && (<p className="text-danger">{errors.discount_description}</p>)}
						</Form.Group>
                        <Form.Label className="lsttxt-instp">It will be displayed on your coupon</Form.Label>
                        </Col>
					  )} 
					  {inputs.coupon_type==='Loyalty Setup' && (
                        <Col md={12} xs={12} className="">
                        <h3>Setup Loyalty Setup</h3>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Buy Item Name</Form.Label>
							<Form.Select aria-label="Default select example" name="buy_item_name" value={inputs.buy_item_name} onChange={handleOnChange}>
                              <option value="">Select Product or Service</option>
							  {product && product.map((val,index) => {
								return(
									<option value={val.id}>{val.name}</option>
								)
							  })} 
                           </Form.Select>
							{errors.buy_item_name && (<p className="text-danger">{errors.buy_item_name}</p>)}
						</Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Buy Quantity Needed</Form.Label>
                           <div className="btnqnty">
                              <input type="text" name="buy_qty_need" value={inputs.buy_qty_need} className="input-qty input-rounded"/>
                              <button onClick={BuyQtyNeedIncCount} className="qty-btn-plus btn-primary btn-rounded mr-1" type="button"><i className="fa fa-plus"></i></button>
                              <button onClick={BuyQtyNeedDecCount} className="qty-btn-minus btn-primary btn-rounded ml-1" type="button"><i className="fa fa-minus"></i></button>
                           </div>
						   {errors.buy_qty_need && (<p className="text-danger">{errors.buy_qty_need}</p>)}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Get Item Name (Free)</Form.Label>
							<Form.Select aria-label="Default select example" name="get_item_name" value={inputs.get_item_name} onChange={handleOnChange}>
                              <option value="">Select Product or Service</option>
							  {product && product.map((val,index) => {
								return(
									<option value={val.id}>{val.name}</option>
								)
							  })} 
                           </Form.Select>
							{errors.get_item_name && (<p className="text-danger">{errors.get_item_name}</p>)}
						</Form.Group>
                        <Col md={12} xm={12} className="selctimg-mrktng cou-img-slide">
                            <div className="cusradio hmtp-scrl">
							<ul className="cou-img-slide">
							{/*<li>
                             <input className="radio-label hmtp-scldv" type="radio" name="image" id="one" value="1" onChange={handleOnChange} checked={inputs.image === "1"} /><label className="button-label" htmlFor="one"><img src= {'assets/images/coupan.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
                             </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="two" value="2" onChange={handleOnChange} checked={inputs.image === "2"}/><label className="button-label" htmlFor="two"><img src= {'assets/images/coupan-2.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							</li>*/}
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="three" value="3" onChange={handleOnChange1} checked={inputs.image === "3"}/><label className="button-label" htmlFor="three"><img src= {'assets/images/coupan-3.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
                             </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="four" value="4" onChange={handleOnChange1} checked={inputs.image === "4"}/><label className="button-label" htmlFor="four"><img src= {'assets/images/coupan-4.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="five" value="5" onChange={handleOnChange1} checked={inputs.image === "5"}/><label className="button-label" htmlFor="five"><img src= {'assets/images/coupan-5.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="six" value="6" onChange={handleOnChange1} checked={inputs.image === "6"}/><label className="button-label" htmlFor="six"><img src= {'assets/images/coupan-6.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="seven" value="7" onChange={handleOnChange1} checked={inputs.image === "7"}/><label className="button-label" htmlFor="seven"><img src= {'assets/images/coupan-7.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="eight" value="8" onChange={handleOnChange1} checked={inputs.image === "8"}/><label className="button-label" htmlFor="eight"><img src= {'assets/images/coupan-8.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="nine" value="9" onChange={handleOnChange1} checked={inputs.image === "9"}/><label className="button-label" htmlFor="nine"><img src= {'assets/images/coupan-9.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="ten" value="10" onChange={handleOnChange1} checked={inputs.image === "10"}/><label className="button-label" htmlFor="ten"><img src= {'assets/images/coupan-10.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="eleven" value="11" onChange={handleOnChange1} checked={inputs.image === "11"}/><label className="button-label" htmlFor="eleven"><img src= {'assets/images/coupan-11.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							</ul>
							</div>
                           </Col>
						   {errors.image && (<p className="text-danger">{errors.image}</p>)}
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Description of your offer (max 30 words)</Form.Label>
                           <Form.Control as="textarea" rows={3} placeholder="Start here..." name="loyalty_description" value={inputs.loyalty_description} onChange={handleOnChangeDes} />
							{errors.loyalty_description && (<p className="text-danger">{errors.loyalty_description}</p>)}
						</Form.Group>
                        <Form.Label className="lsttxt-instp">It will be displayed on your coupon</Form.Label>
                        </Col>
					  )}
					  
					  {inputs.coupon_type==='Invitation' && (
						<Col md={12} xs={12} className="">
						<h3>Setup Invitation</h3>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						   <Form.Label>Item Name</Form.Label>
							<Form.Select aria-label="Default select example" name="item_name" value={inputs.item_name} onChange={handleOnChange}>
							  <option value="">Select Product or Service</option>
							  <option value="All">All</option>
							  {product && product.map((val,index) => {
								return(
									<option value={val.id}>{val.name}</option>
								)
							  })} 
						   </Form.Select>
							{errors.item_name && (<p className="text-danger">{errors.item_name}</p>)}
						</Form.Group>

						<Col md={12} xm={12} className="selctimg-mrktng cou-img-slide">
							<div className="cusradio hmtp-scrl">
							<ul className="cou-img-slide">
							{/*<li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="one" value="1" onChange={handleOnChange} checked={inputs.image === "1"} /><label className="button-label" htmlFor="one"><img src= {'assets/images/coupan.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="two" value="2" onChange={handleOnChange} checked={inputs.image === "2"}/><label className="button-label" htmlFor="two"><img src= {'assets/images/coupan-2.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							</li>*/}
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="three" value="3" onChange={handleOnChange1} checked={inputs.image === "3"}/><label className="button-label" htmlFor="three"><img src= {'assets/images/coupan-3.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="four" value="4" onChange={handleOnChange1} checked={inputs.image === "4"}/><label className="button-label" htmlFor="four"><img src= {'assets/images/coupan-4.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="five" value="5" onChange={handleOnChange1} checked={inputs.image === "5"}/><label className="button-label" htmlFor="five"><img src= {'assets/images/coupan-5.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="six" value="6" onChange={handleOnChange1} checked={inputs.image === "6"}/><label className="button-label" htmlFor="six"><img src= {'assets/images/coupan-6.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="seven" value="7" onChange={handleOnChange1} checked={inputs.image === "7"}/><label className="button-label" htmlFor="seven"><img src= {'assets/images/coupan-7.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="eight" value="8" onChange={handleOnChange1} checked={inputs.image === "8"}/><label className="button-label" htmlFor="eight"><img src= {'assets/images/coupan-8.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="nine" value="9" onChange={handleOnChange1} checked={inputs.image === "9"}/><label className="button-label" htmlFor="nine"><img src= {'assets/images/coupan-9.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="ten" value="10" onChange={handleOnChange1} checked={inputs.image === "10"}/><label className="button-label" htmlFor="ten"><img src= {'assets/images/coupan-10.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							 <li>
							 <input className="radio-label hmtp-scldv" type="radio" name="image" id="eleven" value="11" onChange={handleOnChange1} checked={inputs.image === "11"}/><label className="button-label" htmlFor="eleven"><img src= {'assets/images/coupan-11.png'} className="img-fluid pe-2" alt="Btn Img"/></label>
							 </li>
							</ul>
							</div>
						   </Col>
						   {errors.image && (<p className="text-danger">{errors.image}</p>)}
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						   <Form.Label>Description of your offer (max 30 words)</Form.Label>
						   <Form.Control as="textarea" rows={3} placeholder="Start here..." name="buy_description" value={inputs.buy_description} onChange={handleOnChangeDes} />
							{errors.buy_description && (<p className="text-danger">{errors.buy_description}</p>)}
						</Form.Group>
						<Form.Label className="lsttxt-instp">It will be displayed on your coupon</Form.Label>
						</Col>
						)}
                        </Col>            
                        <Col md={12} xs={12}>
                        <Row class="btbox">
                           <Col md={12} xs={12} className="">
						   <NavLink to="#" className="step2nxt btn-fill" onClick={handleShowTab2}> Save & Continue </NavLink>
						   </Col> 
                        </Row>
                        </Col>                
                     </Row>
				  )}
				  {showtab3 && (
                     <Row className="step3">
                        <Col md={12} xs={12}>
                        <h3>Coupon Name</h3>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						{/*<Form.Label>Enter the Promotion Name</Form.Label>*/}
                           <Form.Control type="text" placeholder="E.g., Special October Offer" name="promotion_name" value={inputs.coupon_name} onChange={handleOnChange} readOnly  />
						   {errors.promotion_name && (<p className="text-danger">{errors.promotion_name}</p>)}
						</Form.Group>
                        {/*<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Choose Your Coupon</Form.Label>
                           <Form.Select aria-label="Default select example" name="your_coupon" value={inputs.your_coupon} onChange={handleOnChange}>
                              <option value="">Coupon Name</option>
                              <option value="One">One</option>
                              <option value="Two">Two</option>
                              <option value="Three">Three</option>
                           </Form.Select>
						   {errors.your_coupon && (<p className="text-danger">{errors.your_coupon}</p>)}
                        </Form.Group>*/}
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Select Option</Form.Label>
                           <Form.Select aria-label="Default select example" className="mb-3" name="select_option" value={inputs.select_option} onChange={handleOnChange}>
                              <option value="Send it to my customers">Send it to my customers. (Free of Charge)</option>
                              {/*<option value="Send with Specific Conditions">Send with Specific Conditions</option>*/}
                           </Form.Select>
						   {errors.select_option && (<p className="text-danger">{errors.select_option}</p>)}
                        </Form.Group>
                        <Col md={12} xs={12}>
                        <Form.Label>Target Customers</Form.Label>
                        </Col>
                        <Col md={12} xs={12} className="borderinput mb-3">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Select aria-label="Default select example" name="target_customers" value={inputs.target_customers} onChange={handleOnChange}>
                              <option value="All">ALL</option>
							  {inputs.coupon_type!=='Loyalty Setup' && (
								<option value="Based on Criteria">Based on Criteria</option>
							  )}
							  <option value="Selected Customer">Selected Customer</option>
                              <option value="All New Customer">All New Customer</option>
                           </Form.Select>
                        </Form.Group>
						{inputs.target_customers==='Based on Criteria' && (
                        <Col md={12} xs={12} className="bocshow">
						<Row>
                           <Col md={12} xs={12} className="px-2 cusradio cratcpn-custmrdo">
							<div className="border-blue pb-2">
                           <div class="p-rltv">
                              <input class="radio-label" type="radio" name="days_type" id="days_type1" value="search_customer" checked={inputs.days_type === "search_customer"} onChange={handleOnChangeDays}/>
                              <label class="button-label" for="days_type1">Search Customers</label>
                           </div>
						   <Row className="mx-0">
						   <Col md={6} xs={12} className="mb-2 inline-txtinput">
						    <InputGroup>
							 <InputGroup.Text>Has Visited In The Past</InputGroup.Text>
							 <Form.Control placeholder="60" className="setinputinline" name="past_days" value={inputs.past_days} onChange={handleNumberChange }/>
							 <InputGroup.Text>Days</InputGroup.Text>
							</InputGroup>
						   </Col>
						   <Col md={6} xs={12} className="mb-2 inline-txtinput">
						    <InputGroup>
							 <InputGroup.Text>Has Spent Over Amount</InputGroup.Text>
							 <Form.Control placeholder="$100" className="setinputinline" name="amount" value={inputs.amount} onChange={handleNumberChange } />
							</InputGroup>
						   </Col>
						   </Row>
						   </div>
                           </Col>
						   {errors.past_days && (<p className="text-danger">{errors.past_days}</p>)}
						   {errors.amount && (<p className="text-danger">{errors.amount}</p>)}
						   <Col md={10} xs={12} className="px-2 mt-3 inline-txtinput">
						    <InputGroup className="border-blue">
							 <InputGroup.Text>
							 <div class="cusradio ">
                              <input class="radio-label" type="radio" name="days_type" id="days_type2" value="not_returned" checked={inputs.days_type === "not_returned"} onChange={handleOnChangeDays} />
                              <label class="button-label" for="days_type2">Has NOT Returned in The Past</label>
                             </div>
							 </InputGroup.Text>
							 <Form.Control placeholder="60" className="mx-40px" name="days" value={inputs.days} onChange={handleNumberChange } />
							 <InputGroup.Text>Days</InputGroup.Text>
							</InputGroup>
						   </Col>
						   {errors.past_days_ret && (<p className="text-danger">{errors.days}</p>)}
						 </Row>
						
                      
						  {(() => {
							if (inputs.coupon_type==='Buy & Get' || inputs.coupon_type==='Discount Offer') {
							  return (<Form.Group className="mb-3 mt-3 ckbxgrn" controlId="">
							   <div key="default-1" className="">
								  <Form.Check type="checkbox" id="default-1" label="Auto send the coupons" name="base_auto_send_the_coupons" value="Auto send the coupons" checked={inputs.base_auto_send_the_coupons === 1} onChange={handleCheckboxChangeAutoSend}/>
							   </div>
						  </Form.Group>)
							}															
						  })()}
                        </Col>
						)}
						{inputs.target_customers==='Selected Customer' && (
                        <Col md={12} xs={12} className="ancshow">
							{/*<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Subscription Period (Months)</Form.Label>
                           <div className="btnqnty">
                              <input type="text" name="subscription_period" value={inputs.subscription_period} className="input-qty input-rounded"/>
                              <button onClick={SubPerIncCount} className="qty-btn-plus btn-primary btn-rounded mr-1" type="button"><i className="fa fa-plus"></i></button>
                              <button onClick={SubPerDecCount} className="qty-btn-minus btn-primary btn-rounded ml-1" type="button"><i className="fa fa-minus"></i></button>
                           </div>
						   {errors.subscription_period && (<p className="text-danger">{errors.subscription_period}</p>)}
							</Form.Group>*/}
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Label>Zip Codes</Form.Label>
                           <TagsInput value={selected} onChange={setSelected} name="zip_code" placeHolder="Zip Codes" />
						   {errors.zip_code && (<p className="text-danger">{errors.zip_code}</p>)}
                        </Form.Group>
                        {/*<Form.Group className="mb-3 ckbxgrn" controlId="">
                           <div key="default-1" className="">
                              <Form.Check type="checkbox" id="default-1" label="Auto send the coupons" name="new_auto_send_the_coupons" value="Auto send the coupons" checked={inputs.new_auto_send_the_coupons === 1} onChange={handleCheckboxChangeNew} />
                           </div>
                        </Form.Group>*/}
                        </Col>
						)}
						{/*inputs.target_customers==='All New Customer' && (
							<Col md={12} xs={12} className="ancshow">
							<Form.Group className="mb-3 ckbxgrn" controlId="">
                           <div key="default-1" className="">
                              <Form.Check type="checkbox" id="default-1" label="Auto send the coupons" name="new_cus_auto_send_the_coupons" value="Auto send the coupons" checked={inputs.new_cus_auto_send_the_coupons === 1} onChange={handleCheckboxChangeNewCus} />
                           </div>
                        </Form.Group>
                        </Col>
						)*/}
                        </Col>
                        </Col>            
                        <Col md={12} xs={12}>
                        <Row class="btbox">
                           <Col md={12} xs={12} className="">
						   <NavLink to="#" className="step3nxt btn-fill" onClick={handleShowTab3}> Save & Continue </NavLink>
						   </Col> 
                        </Row>
                        </Col>                
                     </Row>
				  )}
				  {showtab4 && (
                     <Row className="step4">
                        <Col md={12} xs={12}>
						{(() => {
							if (inputs.image==='1') {
							  return (<Col md={12} xs={12} className="py-2 cupn-crd mb-3">
							   <div className="coupon">
								  <div className="left">
									 <div>Discount</div>
								  </div>
								  <div className="center">
									 <div>
										<h2>{inputs.coupon_name}</h2>
										<p>{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
										<p>{getCoupondes(inputs.coupon_type)}</p>
											{inputs.date_type ==='Start & End' ? (
												<p>Start Date : <span>{inputs.start_date}</span> &nbsp;&nbsp;End Date :<span>{inputs.end_date}</span> </p>
											) : (
												<p>Return Before : <span>{inputs.return_before}</span> </p>
											)}
									 </div>
								  </div>
								  
							   </div>
							   </Col> )
							} else if (inputs.image==='2') {
							  return (<Col className="mb-3 main-copn">
								  <Row className="mx-0 second-coupan mb-2">
								  <Col className="align-self-center copn-text">
								    <h5>{inputs.coupon_name}</h5>
										<p>{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
										<p>{getCoupondes(inputs.coupon_type)}</p>
										{inputs.date_type ==='Start & End' ? (
										  <>
											<p>Start Date : <span>{inputs.start_date}</span> </p>
											<p>End Date :<span>{inputs.start_date}</span> </p>
											</>
										) : (
												<p>Return Before : <span>{inputs.return_before}</span> </p>
										)}
								  </Col>
								  <Col className="align-self-end text-end"><img src={process.env.REACT_APP_BASE_URL + '/assets/images/cupn-img.png'} className="img-fluid w-100" alt="Hmtp Postr"/></Col>
								 </Row>
							 </Col>)
							}else if (inputs.image==='3') {
							  return (<Col className="mb-3 main-copn">
								<Row className="mx-0 third-copn ">
								   <Col className="align-self-end text-white col copn-text text-end mb-5 px-5">
									<h5 className="text-dark">{inputs.coupon_name}</h5>
										<p className="text-dark">{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
										<p className="text-dark">{getCoupondes(inputs.coupon_type)}</p>
											{inputs.date_type ==='Start & End' ? (
												<p className="text-dark">Start Date : <span>{inputs.start_date}</span> &nbsp;&nbsp;End Date :<span>{inputs.end_date}</span> </p>
											) : (
												<p className="text-dark">Return Before : <span>{inputs.return_before}</span> </p>
											)}
								   </Col>
								   
								  </Row>
							</Col>)
							}else if (inputs.image==='4') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 four-copn ">
									   <Col className="align-self-end text-dark col copn-text text-end">
										<h5 className="text-dark">{inputs.coupon_name}</h5>
											<p className="text-dark">{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
											<p className="text-dark">{getCoupondes(inputs.coupon_type)}</p>
											{inputs.date_type ==='Start & End' ? (
												<p className="text-dark">Start Date : <span>{inputs.start_date}</span> &nbsp;&nbsp;End Date :<span>{inputs.end_date}</span> </p>
											) : (
												<p className="text-dark">Return Before : <span>{inputs.return_before}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (inputs.image==='5') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 five-copn justify-content-center ">
									   <Col md={6} xs={8} className="align-self-center text-dark col copn-text col-6 ">
										<h5 className="text-dark">{inputs.coupon_name}</h5>
											<p className="text-dark">{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
											<p className="text-dark">{getCoupondes(inputs.coupon_type)}</p>
											{inputs.date_type ==='Start & End' ? (
											  <>
												<p className="text-dark">Start Date : <span>{inputs.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{inputs.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Before : <span>{inputs.return_before}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (inputs.image==='6') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 six-copn justify-content-center ">
									   <Col md={6} xs={8} className="align-self-center text-dark col copn-text col-6 ">
										<h5 className="text-dark">{inputs.coupon_name}</h5>
											<p className="text-dark">{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
											<p className="text-dark">{getCoupondes(inputs.coupon_type)}</p>
											{inputs.date_type ==='Start & End' ? (
											  <>
												<p className="text-dark">Start Date : <span>{inputs.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{inputs.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Before : <span>{inputs.return_before}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (inputs.image==='7') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 seven-copn justify-content-end ">
									   <Col md={6} xs={8} className="align-self-center text-white col copn-text text-end col-6 mt-5">
										<h5 className="">{inputs.coupon_name}</h5>
											<p>{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
										<p>{getCoupondes(inputs.coupon_type)}</p>
										{inputs.date_type ==='Start & End' ? (
										  <>
											<p>Start Date : <span>{inputs.start_date}</span> </p>
											<p>End Date :<span>{inputs.start_date}</span> </p>
											</>
										) : (
												<p>Return Before : <span>{inputs.return_before}</span> </p>
										)}
									   </Col>
									  </Row>
								</Col>)
							}else if (inputs.image==='8') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 eight-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{inputs.coupon_name}</h5>
											<p className="text-dark">{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
											<p className="text-dark">{getCoupondes(inputs.coupon_type)}</p>
											{inputs.date_type ==='Start & End' ? (
											  <>
												<p className="text-dark">Start Date : <span>{inputs.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{inputs.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Before : <span>{inputs.return_before}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (inputs.image==='9') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 nine-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{inputs.coupon_name}</h5>
											<p className="text-dark">{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
											<p className="text-dark">{getCoupondes(inputs.coupon_type)}</p>
											{inputs.date_type ==='Start & End' ? (
											  <>
												<p className="text-dark">Start Date : <span>{inputs.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{inputs.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Before : <span>{inputs.return_before}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (inputs.image==='10') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 ten-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{inputs.coupon_name}</h5>
											<p className="text-dark">{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
											<p className="text-dark">{getCoupondes(inputs.coupon_type)}</p>
											{inputs.date_type ==='Start & End' ? (
											  <>
												<p className="text-dark">Start Date : <span>{inputs.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{inputs.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Before : <span>{inputs.return_before}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (inputs.image==='11') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 eleven-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{inputs.coupon_name}</h5>
											<p className="text-dark">{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
											<p className="text-dark">{getCoupondes(inputs.coupon_type)}</p>
											{inputs.date_type ==='Start & End' ? (
											  <>
												<p className="text-dark">Start Date : <span>{inputs.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{inputs.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Before : <span>{inputs.return_before}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}															
						  })()}
						
                        <h3>Coupon Confirmation</h3>
                        <Row className="cupn-crgdg mx-0">
                           <Col md={12} xs={12} className="linebtm py-3">
                           <h4>Coupon Detail</h4>
                           </Col>
                           <Col md={12} xs={12} className="pt-3">
                           <Row className="cntrcnt">
								<Col md={6} xs={6}>
                              <span>Select Option</span></Col>
                              <Col md={6} xs={6}>
                              <p>{inputs.select_option}</p>
                              </Col>
                              <Col md={6} xs={6}>
                              <span>Target Customers</span></Col>
                              <Col md={6} xs={6}>
                              <p>{inputs.target_customers}</p>
                              </Col>
                              <Col md={6} xs={6}>
                              <span>Chosen Zip Codes</span></Col>
                              <Col md={6} xs={6}>
							  {inputs.target_customers==='Selected Customer' ? (
								<p>{selected.join(',')} 
								</p>
							  ) : (
                              <p>All</p>
							  )}
                              </Col>
                              
                              
                           </Row>
                           </Col>
                        </Row>
                        
                        </Col>            
                        <Col md={12} xs={12}>
                        <Row class="btbox">
                           <Col md={12} xs={12} className="">
						   <NavLink to="#" className="step4nxt btn-fill" onClick={handleShowTab4}> Create and Send </NavLink>
						   </Col> 
                        </Row>
                        </Col>                
                     </Row>
				  )}
                  </form>
                  </Col>
               </Row>
			   {loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
            </Container>
         </section>
		 {/* ========== Start Coupon Modal ========== */}
		  <Modal show={show} onHide={handleClose} className="popupwrngcnt">
            <Modal.Header closeButton>
               <h3 className="mdlttltxt">Coupon</h3>
            </Modal.Header>
            <Modal.Body>
               
               <Col md={12} xs={12} className="ppinpt">
					 {(() => {
							if (inputs.image==='1') {
							  return (<Col md={12} xs={12} className="py-2 cupn-crd mb-3">
							   <div className="coupon">
								  <div className="left">
									 <div>Discount</div>
								  </div>
								  <div className="center">
									 <div>
										<h2>{inputs.coupon_name}</h2>
										<p>{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
										<p>{getCoupondes(inputs.coupon_type)}</p>
											{inputs.date_type ==='Start & End' ? (
												<p>Start Date : <span>{inputs.start_date}</span> &nbsp;&nbsp;End Date :<span>{inputs.end_date}</span> </p>
											) : (
												<p>Return Before : <span>{inputs.return_before}</span> </p>
											)}
									 </div>
								  </div>
								  
							   </div>
							   </Col> )
							} else if (inputs.image==='2') {
							  return (<Col className="mb-3 main-copn">
								  <Row className="mx-0 second-coupan mb-2">
								  <Col className="align-self-center copn-text">
								    <h5>{inputs.coupon_name}</h5>
										<p>{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
										<p>{getCoupondes(inputs.coupon_type)}</p>
										{inputs.date_type ==='Start & End' ? (
										  <>
											<p>Start Date : <span>{inputs.start_date}</span> </p>
											<p>End Date :<span>{inputs.start_date}</span> </p>
											</>
										) : (
												<p>Return Before : <span>{inputs.return_before}</span> </p>
										)}
								  </Col>
								  <Col className="align-self-end text-end"><img src={process.env.REACT_APP_BASE_URL + '/assets/images/cupn-img.png'} className="img-fluid w-100" alt="Hmtp Postr"/></Col>
								 </Row>
							 </Col>)
							}else if (inputs.image==='3') {
							  return (<Col className="mb-3 main-copn">
								<Row className="mx-0 third-copn ">
								   <Col className="align-self-end text-white col copn-text text-end mb-5 px-5">
									<h5 className="text-dark">{inputs.coupon_name}</h5>
										<p className="text-dark">{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
										<p className="text-dark">{getCoupondes(inputs.coupon_type)}</p>
											{inputs.date_type ==='Start & End' ? (
												<p className="text-dark">Start Date : <span>{inputs.start_date}</span> &nbsp;&nbsp;End Date :<span>{inputs.end_date}</span> </p>
											) : (
												<p className="text-dark">Return Before : <span>{inputs.return_before}</span> </p>
											)}
								   </Col>
								   
								  </Row>
							</Col>)
							}else if (inputs.image==='4') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 four-copn ">
									   <Col className="align-self-end text-dark col copn-text text-end">
										<h5 className="text-dark">{inputs.coupon_name}</h5>
											<p className="text-dark">{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
											<p className="text-dark">{getCoupondes(inputs.coupon_type)}</p>
											{inputs.date_type ==='Start & End' ? (
												<p className="text-dark">Start Date : <span>{inputs.start_date}</span> &nbsp;&nbsp;End Date :<span>{inputs.end_date}</span> </p>
											) : (
												<p className="text-dark">Return Before : <span>{inputs.return_before}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (inputs.image==='5') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 five-copn justify-content-center ">
									   <Col md={6} xs={8} className="align-self-center text-dark col copn-text col-6">
										<h5 className="text-dark">{inputs.coupon_name}</h5>
											<p className="text-dark">{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
											<p className="text-dark">{getCoupondes(inputs.coupon_type)}</p>
											{inputs.date_type ==='Start & End' ? (
											  <>
												<p className="text-dark">Start Date : <span>{inputs.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{inputs.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Before : <span>{inputs.return_before}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (inputs.image==='6') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 six-copn justify-content-center ">
									   <Col md={6} xs={8} className="align-self-center text-dark col copn-text col-6 ">
										<h5 className="text-dark">{inputs.coupon_name}</h5>
											<p className="text-dark">{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
											<p className="text-dark">{getCoupondes(inputs.coupon_type)}</p>
											{inputs.date_type ==='Start & End' ? (
											  <>
												<p className="text-dark">Start Date : <span>{inputs.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{inputs.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Before : <span>{inputs.return_before}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (inputs.image==='7') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 seven-copn justify-content-end ">
									   <Col md={6} xs={8} className="align-self-center text-white col copn-text text-end col-6 mt-5">
										<h5 className="">{inputs.coupon_name}</h5>
											<p>{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
										<p>{getCoupondes(inputs.coupon_type)}</p>
										{inputs.date_type ==='Start & End' ? (
										  <>
											<p>Start Date : <span>{inputs.start_date}</span> </p>
											<p>End Date :<span>{inputs.start_date}</span> </p>
											</>
										) : (
												<p>Return Before : <span>{inputs.return_before}</span> </p>
										)}
									   </Col>
									  </Row>
								</Col>)
							}else if (inputs.image==='8') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 eight-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{inputs.coupon_name}</h5>
											<p className="text-dark">{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
											<p className="text-dark">{getCoupondes(inputs.coupon_type)}</p>
											{inputs.date_type ==='Start & End' ? (
											  <>
												<p className="text-dark">Start Date : <span>{inputs.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{inputs.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Before : <span>{inputs.return_before}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (inputs.image==='9') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 nine-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{inputs.coupon_name}</h5>
											<p className="text-dark">{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
											<p className="text-dark">{getCoupondes(inputs.coupon_type)}</p>
											{inputs.date_type ==='Start & End' ? (
											  <>
												<p className="text-dark">Start Date : <span>{inputs.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{inputs.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Before : <span>{inputs.return_before}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (inputs.image==='10') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 ten-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{inputs.coupon_name}</h5>
											<p className="text-dark">{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
											<p className="text-dark">{getCoupondes(inputs.coupon_type)}</p>
											{inputs.date_type ==='Start & End' ? (
											  <>
												<p className="text-dark">Start Date : <span>{inputs.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{inputs.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Before : <span>{inputs.return_before}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}else if (inputs.image==='11') {
							  return (<Col className="mb-3 main-copn">
									<Row className="mx-0 eleven-copn justify-content-center ">
									   <Col md={7} xs={9} className="align-self-center text-dark col copn-text col-6 mt-5">
										<h5 className="text-dark">{inputs.coupon_name}</h5>
											<p className="text-dark">{getCoupontype(inputs.coupon_type,inputs.discount_percentage,inputs.minimum_purchase_amount,inputs.product_name_buy,inputs.product_name,inputs.buy_qty,inputs.get_qty)}</p>
											<p className="text-dark">{getCoupondes(inputs.coupon_type)}</p>
											{inputs.date_type ==='Start & End' ? (
											  <>
												<p className="text-dark">Start Date : <span>{inputs.start_date}</span> </p>
												<p className="text-dark">End Date :<span>{inputs.start_date}</span> </p>
												</>
											) : (
													<p className="text-dark">Return Before : <span>{inputs.return_before}</span> </p>
											)}
									   </Col>
									  </Row>
								</Col>)
							}															
						  })()}
               </Col>
               
			   
            </Modal.Body>
            
         </Modal>
		  {/* ========== End Coupon Modal ========== */}
        </>
    )
}
export default Coupon;